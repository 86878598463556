import 'bootstrap';
import "js-float-calculation";


import AirDatepicker from 'air-datepicker';
import localeSl from '../../lib/sl';

// DATEPICKER

const today = new Date();
today.setHours(23);
today.setMinutes(59);

let maxIndex = 0;
var oneYearFromNow = new Date();
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
const settings = {
	locale: localeSl,
	startDate: today,
	maxDate: oneYearFromNow,
	dateFormat: 'd. M. yyyy',
	position: 'top left',
	
	onSelect: function(data) {
		$(data.datepicker.$el).trigger('change');
	}
};
const settingsTime = {
	...settings,
	timepicker: true,
	minDate: today,
	maxDate: oneYearFromNow,
};

var datepickersList = [];
function setDatePicker( index, element, settings ) {
	if( element.value ){
		if( element.value.includes(':') ){
			let parsedDate = fnc.parseDateWithTime(element.value);
			if ( parsedDate instanceof Date && parsedDate != 'Invalid Date' ) {
				settings = {
					...settings,
					selectedDates: parsedDate
				};
			}
		} else {
			function parseDate( dateString ) {
				// Split the string into its components (day, month, year, hour, minute)
				const parts = dateString.split(".");
				const [day, month, year] = parts;
				
				// Create a Date object with individual components
				return new Date(year, month - 1, day);
			}
			let parsedDate = parseDate(element.value);
			if ( parsedDate instanceof Date && parsedDate != 'Invalid Date' ) {
				settings = {
					...settings,
					selectedDates: parsedDate
				};
			}
		}
	}
	
	datepickersList[index] = new AirDatepicker(element, settings);
	
	$(element).siblings('.icon').on( "click", function() {
		$(this).siblings('input').focus();
		datepickersList[index].show();
	});
}

function datepickers(){
	$('input.datepickeronlydate').each(function(index){
		setDatePicker(index, this, settings);
		maxIndex += 1;
	});
	$('input.datepicker').each(function(index){
		setDatePicker(index, this, settingsTime);
		maxIndex += 1;
	});
}



function deleteClickTrigger(){
	if($('.deleteVloga').length){
		$('.deleteVloga').on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			let recordID = $(this).data('dataid');
			$.post('/evloge/vloga/delete/'+recordID, { }, function(data){
				window.location.href = `/evloge`;
			})
		})
	}
}


function percentChanged(){
	$('input.percent').each(function(){
		
		var part = this.dataset.part;
		var sum = this.dataset.sum;
		
		let percentVal = parseNumber($('#'+part)) / parseNumber($('#'+sum)) * 100;
		
		if(percentVal >= 0 && !isNaN(percentVal)){
			printNumber($(this), percentVal, true, true);
		}
	});
	
	$('input.sumPercent').each(function(){
		
		var fields = this.dataset.fields.split(',');
		var cntFields = 0;
		let sumVal = parseFloat('0.00');
		fields.forEach(field => {
			if($('#'+field).length){
				cntFields++;
				let fieldValue = parseNumber($('#'+field))
				sumVal = sumVal.add(fieldValue); // because of float values, you cant simply +
			}
		});
		
		if(sumVal >= 0 && !isNaN(sumVal)){
			
			if($(this).hasClass('whole')){
				sumVal = Math.round(sumVal);
			}
			
			printNumber($(this), sumVal, true, true);
			
			/* if($(this).data('type') == 'percent' && sumVal != 100){
				$(this).addClass('is-invalid');
			} else {
				$(this).removeClass('is-invalid');
			} */
		}
	});
	
}

function copyValues(){
	$('input[data-copy]').each(function(){
		
		const copyValueId = this.dataset.copy;
		const copyValue = $(`#${copyValueId}`).val();
		
		$(this).val(copyValue);
		
		$(this).removeClass('is-invalid');
		$(this).siblings(`.invalid-feedback`).remove();
	});
}

function sumChanged(){
	$('input.sum:not(.sumPercent)').each(function(){
		
		var fields = this.dataset.fields.split(',');
		var avg = $(this).hasClass('avg');
		var cntFields = 0;
		let sumVal = parseFloat('0.00');
		fields.forEach(field => {
			if($('#'+field).length){
				cntFields++;
				let fieldValue = parseNumber($('#'+field))
				sumVal = sumVal.add(fieldValue); // because of float values, you cant simply +
			}
		});
		
		if(sumVal >= 0 && !isNaN(sumVal)){
			
			if(avg){
				sumVal = sumVal/cntFields;
			}
			printNumber($(this), sumVal, true);
			
			if($(this).data('type') == 'percent' && sumVal != 100){
				//$(this).addClass('is-invalid');
			} else {
				$(this).removeClass('is-invalid');
			}
		}
	});
}

function sumContainChanged(){
	$('input.sumcontains').each(function(){
		var containString = $(this).data('contains');
		let sumVal = parseFloat('0.00');
		var avg = $(this).hasClass('avg');
		var cntFields = 0;
		$('input[id^="'+containString+'"]').each(function(){
			let field = $(this).attr('id');
			if($('#'+field).length){
				let fieldValue = parseNumber($('#'+field));
				if(fieldValue>0){
					cntFields++;
				}
				sumVal = sumVal.add(fieldValue);
			}
		});
		if(sumVal >= 0 && !isNaN(sumVal)){
			if(avg && cntFields > 0){
				sumVal = sumVal/cntFields;
			}
			printNumber($(this), sumVal, true);
			
			if($(this).data('type') == 'percent' && sumVal != 100){
				//$(this).addClass('is-invalid');
			} else {
				$(this).removeClass('is-invalid');
			}
		}
	})
}

function validateRestrictions(valid = true){
	const elements = document.querySelectorAll(`[data-percent], [data-equal], [data-max], [data-maxpre], [data-mintext]`);
	elements.forEach(function(element){
		
		let text = '';
		let validated = true;
		const inputedValue = parseNumber($(element));
		
		if(element.hasAttribute('data-percent')){
			const percent = parseInt(element.dataset.percent);
			let sumValue = 0;
			if(detectFieldId(`#${element.dataset.sum}`)){
				sumValue = parseNumber($(`#${element.dataset.sum}`));
			}
			
			if( inputedValue && sumValue ){
				const calculatedValue = percent/100 * sumValue;
				if(calculatedValue < inputedValue){
					validated = false;
					const textToShow = element.dataset.textpercentage ?? `Znesek ne sme preseči ${percent} % skupne vrednosti programa/projekta.`;
					text = fnc.appendTextWithBR(text, textToShow);
				}
			}
		}
		if(element.hasAttribute('data-max')){
			var max = 0;
			max = parseInt(element.dataset.max);
			
			const percent = parseInt(element.dataset.percentmax) ?? parseInt(element.dataset.percent) ;
			
			let sumValue = 0;
			if(detectFieldId(`#${ element.dataset.sum}`)){
				sumValue = parseNumber($(`#${ element.dataset.sum}`));
			}
			
			if( inputedValue  && sumValue ){
				const calculatedValue = percent/100 * sumValue;
				
				if( calculatedValue < inputedValue || inputedValue > max ){
					validated = false;
					
					const textToShow = element.dataset.textpercentagesum ?? `Znesek ne sme preseči ${fnc.numberWithCommas(max)} EUR.`;
					text = fnc.appendTextWithBR(text, textToShow);
				}
			}
		}
		
		if(element.hasAttribute('data-min')){
			var min = 0;
			
			min = parseInt(element.dataset.min);
			
			const percent = parseInt(element.dataset.percentmax) ?? parseInt(element.dataset.percent) ;
			
			let sumValue = 0;
			if(detectFieldId(`#${ element.dataset.sum}`)){
				sumValue = parseNumber($(`#${ element.dataset.sum}`));
			}
			
			if( inputedValue  && sumValue ){
				const calculatedValue = percent/100 * sumValue;
				
				if( calculatedValue < inputedValue || inputedValue < min ){
					validated = false;
					
					const textToShow = element.dataset.textpercentagesum ?? `Znesek mora biti večji od ${fnc.numberWithCommas(min)} EUR.`;
					text = fnc.appendTextWithBR(text, textToShow);
				}
			}
		}
		if(element.hasAttribute('data-equal')){
			let equalValue = 0;
			const selectors = element.dataset.equal.split(",");
			for( let selectorId of selectors){
				selectorId = selectorId.trim();
				if(selectorId == ''){
					break;
				}
				if(detectFieldId(`#${selectorId}`)){
					equalValue = parseNumber($(`#${selectorId}`));
				}
				
				if( inputedValue && equalValue ){
					if( inputedValue !== equalValue ){
						validated = false;
						const textToShow = element.dataset.textequal ?? `Zneska prihodkov in odhodkov se morata ujemati.`;
						text = fnc.appendTextWithBR(text, textToShow);
						break;
					}
				}
			}
		}
		
		if(element.hasAttribute('data-mintext')){
			const minTextLen = parseInt(element.dataset.mintext);
			var currentLength = $(element).val().length;
			if (currentLength < minTextLen) {
				const textToShow = element.dataset.textmintext ?? `Besedilo mora biti dolgo vsaj ${minTextLen} znakov.`;
				text = fnc.appendTextWithBR(text, textToShow);
				validated = false;
			}
		}
		
		$(element).removeClass('is-invalid');
		$(element).siblings(`.invalid-feedback`).remove();
		
		if(!validated){
			valid = false;
			$(element).addClass('is-invalid');
			$(element).after(`<div class="invalid-feedback">${text}</div>`);
		}
		
	});
	return valid;
}

function validateEmail(valid = true){
	const elements = document.querySelectorAll(`[type="email"]`);
	const text = `E-naslov ni veljaven.`;
	let validated = false;
	
	elements.forEach(function(element){
		
		const inputedValue = $(element).val();
		
		if(inputedValue == '') {
			return;
		}
		
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		validated = emailPattern.test(inputedValue);
		
		$(element).removeClass('is-invalid');
		$(element).siblings(`.invalid-feedback`).remove();
		
		if(!validated){
			valid = false;
			$(element).addClass('is-invalid');
			$(element).after(`<div class="invalid-feedback">${text}</div>`);
		}
		
	});
	return valid;
}

function detectFieldId(selector){
	if($(selector).length == 0){
		toasts.show({
			message:`NAPAKA pri primerjanju vrednosti polj - ID polja ne obstaja! (Selector: '${selector}' )`,
			color: 'danger'
		});
		return false;
	}
	return true;
}

function triggerFieldChangeMultiplyContains(){
	$('input.multiplay').each(function(){
		
		let field1 = $(this).data('field1');
		let field1Value = parseNumber($('#'+field1));
		let field2 = $(this).data('field2');
		let field2Value = parseNumber($('#'+field2));
		
		let multiplyValue = 0.00;
		multiplyValue = field1Value * field2Value;
		
		if(!isNaN(multiplyValue)){
			printNumber($(this), multiplyValue, true);
			sumContainChanged();
			sumChanged();
			percentChanged();
			copyValues();
		}
	})
}

function triggerNumChange(){
	$(document).on('change', 'input.number:not(.nodecimal)', function(){
		
		triggerFieldChangeMultiplyContains();
		sumContainChanged();
		sumChanged();
		percentChanged();
		copyValues();
		
		validateRestrictions();
		
		printNumber($(this), parseNumber($(this)), true);
	});
}

function triggerInputChange(){
	$(document).on('keydown keypress change paste ', 'input, textarea, select', function(){
		
		validateRestrictions();
		interlockingTableInputs();
		validateEmail();
		
		if($(this).is(':checkbox') && $(this).prop("checked")) {
			$(this).siblings('.checkmark').removeClass('is-invalid');
			$(this).parent().siblings('p').removeClass('is-invalid');
			$(this).parent().siblings('p .invalid-feedback').remove();
		}
	});
}

function parseNumber(element){
	let value = String($(element).val());
	if(value !== ''){
		if($(element).hasClass('whole')){
			value = value.replace(/[^0-9]*/g, ''); // remove all text, leave only numbers
			value = parseInt(value);
		}else{
			value = value.replace(/[^0-9,]*/g, ''); // remove all text, leave only numbers and commas
			value = value.replaceAll(',','.');
			value = parseFloat(value);
		}
	}
	if(isNaN(value)){
		value = 0;
	}
	return value;
}

function printNumber(element, value, printZero = false, percent = false){
	if(value !== ''){
		if(value !== 0){
			if($(element).hasClass('whole')){
				$(element).val(parseInt(value));
			}else if(percent){
				$(element).val(fnc.numberWithCommas(parseFloat(value), 0, 5));
			}else{
				$(element).val(fnc.numberWithCommas(parseFloat(value)));
			}
		}else if(printZero && value === 0){
			if($(element).hasClass('whole')){
				$(element).val('0');
			}else{
				$(element).val('0,00');
			}
		}
		if(percent){
			$(element).val($(element).val() + ' %');
		}
		if($(element).hasClass('eur')){
			$(element).val($(element).val() + ' €');
		}
	}else{
		$(element).val('');
	}
}

/* function detectRequiredDrugoChange(){
	detectRequiredDrugo();
	$(document).on('change', '[id^="status_izvajalca"]', function(){
		detectRequiredDrugo();
	});
}

function detectRequiredDrugo(valid = true){
	const selectField = document.querySelector(`[id^="status_izvajalca"]`);
	const drugiStatus = document.querySelector(`[id^="drugi_status"]`);
	if(selectField){
		if(selectField.value == 'drugo'){
			drugiStatus.required = true;
			if(drugiStatus.value == ''){
				$(drugiStatus).addClass('is-invalid');
				var text = `Če ste izbrali status izvajalca "drugo", je to polje obvezno!`
				$(drugiStatus).after(`<div class="invalid-feedback">${text}</div>`);
				
				return false;
			}else{
				$(drugiStatus).removeClass('is-invalid');
				$(drugiStatus).siblings(`.invalid-feedback`).remove();
			}
		}else{
			$(drugiStatus).removeClass('is-invalid');
			$(drugiStatus).siblings(`.invalid-feedback`).remove();
		}
	}
	return valid;
} */

function validateForm(){
	$('.validateForm').on('click', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		if(fnc.checkOneTab("Oddajanje je onemogočeno!")){
			return;
		}
		
		$('.is-invalid').removeClass('is-invalid');
		$('.invalid-feedback').remove();
		var valid = true;
		
		valid = validateRestrictions(valid);
		//valid = validateClanstvo(valid);
		valid = interlockingTableInputs(valid);
		//valid = detectRequiredDrugo(valid);
		valid = validateEmail(valid);
		
		$( ':input[required]', $('#vloga, #porocilo')).each(function(){
			
			if($(this).is(':checkbox') && !$(this).prop("checked")) {
				valid = false;
				$(this).siblings('.checkmark').addClass('is-invalid');
				$(this).parent().siblings('p').addClass('is-invalid');
				$(this).parent().siblings('p').append(`<div class="invalid-feedback">Tega polja niste izpolnili. Polje je obvezno.</div>`);
				
			}else if(this.value.trim() == '' && $(this).parent().is(':visible') && !$(this).hasClass("is-invalid")){
				valid = false;
				$(this).addClass('is-invalid');
				let char = '/';
				if($(this).hasClass('number')){
					char = '0';
				}
				
				if( $(this).attr('readonly') !== undefined && $(this).attr('tabindex') < 0 ){
					$(this).after(`<div class="invalid-feedback">Zaradi napake se to polje ni samodejno izpolnilo.</div>`);
				}else{
					let field = `Če za vas ni relevantno vnesite: ${char}`;
					if($(this).is('select')){
						field = `Izbrati morate eno od možnosti.`;
					}
					$(this).after(`<div class="invalid-feedback">Tega polja niste izpolnili. ${field}</div>`);
				}
			}
		});
		
		$( '.isUploaded[required]', $('#vloga, #porocilo')).each(function(){
			if(this.value.trim() == 'false' && !$(this).hasClass("is-invalid")){
				valid = false;
				$(this).addClass('is-invalid');
				$(this).parent().parent().addClass('is-invalid');
				$(this).after(`<div class="warning invalid-feedback"><p>Nobene priloge oz. dokazila niste naložili. Priloge oz. dokazila so obvezna!</p></div>`);
			}
		});
		//valid = validateIzjava(valid);
		
		if(!valid){
			toasts.show({
				message:'Ustrezno izpolnite vsa zahtevana polja!',
				color: 'danger',
				time: 10000
			});
			$('.is-invalid').first()[0].scrollIntoView({ behavior: "instant", block: "center", inline: "center" });
		} else {
			$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
			$('#save, #savePorocilo').trigger('click');
			const link = $(this).prop('href');
			$(document).on('saved', function(e){
				window.location.href = link;
			});
		}
	});
	$( ':input[required]', $('#vloga, #porocilo')).on('change', function(){
		if(this.value.trim() != ''){
			$(this).removeClass('is-invalid');
			$(this).closest('.invalid-feedback').remove();
		}
	});
}

/* function validateClanstvo(valid = true){
	if($('#vloga').length && $('.clanstvo').length){
		
		const tables = document.querySelectorAll(`table.clanstvo`);
		let sumValue = 0;
		tables.forEach(function(table){
			const tableId = table.id;
			const rows = table.querySelectorAll(`tr`);
			rows.forEach(function(row){
				if(row.classList.contains(tableId)){
					const inputs = row.querySelectorAll(`input`);
					// ce je usaj en input poln ga štej
					let full = false;
					for (const input of inputs) {
						if (input.value != '') {
							full = true;
							break;
						}
					}
					if(full){
						sumValue++;
					}
				}
			})
		});
		
		const inputField = $(`#skupaj_clani`);
		const inputedValue = parseInt(inputField.val());
		if( sumValue ){
			if( inputedValue == sumValue ){
				$(inputField).removeClass('is-invalid');
				$(`.invalid-feedback`).remove();
				
			}else if( inputedValue != 0 && inputedValue != '' ){
				if(!$(inputField).hasClass('is-invalid')){
					$(inputField).addClass('is-invalid');
					var text = `Število v tem vnosnem polju mora biti enako seštevku članov po kategorijah. (Število vnesenih članov po kategorijah: ${sumValue} )`
					$(inputField).after(`<div class="invalid-feedback">${text}</div>`);
				}
				valid = false;
			}
		}
	}
	return valid;
} */

function interlockingTableInputs(valid){
	const text = '<div class="invalid-feedback">Vsa vnosna polja v vrstici morajo biti izpolnjena!</div>';
	const rows = document.querySelectorAll(".interlocking");
	for ( const row of rows ) { // gremo po vseh vrsticah
		const inputs = row.querySelectorAll("input:not(.sum):not(.multiplay):not(.percent), select");
		var rowHas = false;
		for ( const input of inputs ) { // gremo po vseh inputih, pogledamo če je vsaj eden izpolnjen
			if( input.value != '' ){
				rowHas = true;
				break;
			}
		}
		if(rowHas){
			for ( const input of inputs ) { // gremo po vseh inputih, pogledamo kateri so prazni in njim damo opozorilo
				
				$(input).removeClass('is-invalid');
				$(input).siblings('.invalid-feedback').remove();
				
				if( input.value == '' ){
					$(input).addClass('is-invalid');
					$(input).after(text);
					valid = false;
				}
			}
		}else{ // prazna vrstica
			$(row).find('.is-invalid').siblings('.invalid-feedback').remove();
			$(row).find('.is-invalid').removeClass('is-invalid');
		}
	}
	return valid;
}

function addRow() {
	$(document).on('click', '.addRow', function(e){
		e.preventDefault();
		e.stopPropagation();
		this.blur();
		
		const tableroot = this.dataset.tableroot;
		const tableRow = this.dataset.tablerow;
		var interator = parseInt(this.dataset.interator) + 1;
		this.dataset.interator = interator;
		
		const table = document.querySelector(`table#${tableroot} tbody`);
		const childSumRow = table.querySelector(`.sumRow`);
		const noBottom = table.querySelectorAll(`.between.${tableRow}`);
		
		var newRow = document.querySelector(`table#${tableroot} tr.${tableRow}`).cloneNode(true);
		
		var newRowHtml = newRow.outerHTML;
		newRowHtml = newRowHtml.replaceAll(`_0_`, `_${interator}_`);
		newRowHtml = newRowHtml.replaceAll(`data-iterator="0"`, `data-iterator="${interator}"`);
		
		newRowHtml = newRowHtml.replaceAll(/value="([^"]*)"/gim, ``); // Clear input values in the cloned row
		newRowHtml = newRowHtml.replaceAll(/selected="([^"]*)"/gim, ``);
		
		newRowHtml = newRowHtml.replaceAll(`<td class="counter">1`, `<td class="counter">${interator + 1}`);
		
		var wrapperTable = document.createElement('table');
		wrapperTable.innerHTML = newRowHtml;
		var preparedRow = wrapperTable.firstChild.firstChild;
		
		if (noBottom && noBottom.length > 0 ) { // Append the cloned row to the table before sumRow row
			const lastNoBottom = noBottom[noBottom.length-1];
			appendRow(preparedRow, lastNoBottom, childSumRow, false);
			
		} else if (childSumRow !== null ) { // Append the cloned row to the table before sumRow row
			appendRow(preparedRow, childSumRow);
			
		}else{ // Append the cloned row to the table
			table.appendChild(preparedRow);
		}
		
		// vsakemu polju dodaj required - če si dodal vrstico more bit use required (razen sum in multiply)
		const inputs = newRow.querySelectorAll('input:not(.multiplay):not(.sum):not(.percent), select');
		inputs.forEach(function (elem, i) {
			if (elem.id) {
				let elemId = elem.id;
				elemId = elemId.replace(`_0_`, `_${interator}_`);
				document.getElementById(elemId).required = true;
				
				if(elem.classList.contains('datepickeronlydate')){
					// add date picker to field with class
					maxIndex += 1;
					setDatePicker(maxIndex, document.getElementById(elemId), settings);
				}
			}
		});
		
		var removeRowBtn = this.nextElementSibling;
		if (removeRowBtn) {
			removeRowBtn.classList.remove('hidden');
		}
		
		$(`table#${tableroot} .removeThisRow.disabled`).removeClass('disabled');
		
		function appendRow(preparedRow, beforeRow, sumRow = null, before = true){
			table.insertBefore(preparedRow, (before ? beforeRow : beforeRow.nextSibling));
			
			const allCols = preparedRow.querySelectorAll('td');
			allCols.forEach(function (td, i) {
				const input = td.querySelector('input');
				if(input && (input.classList.contains('number') || input.classList.contains('percent'))){
					sumRow = sumRow == null ? beforeRow : sumRow;
					const currentCol = sumRow.querySelectorAll('td')[i];
					if(currentCol){
						const inputSum = currentCol.querySelector('input.sum:not(.vmesni)');
						if(inputSum){
							inputSum.dataset.fields += `,${input.id}`;
						}
						
						const inputSumVmesni = currentCol.closest('input.vmesni');
						if(inputSumVmesni){
							inputSumVmesni.dataset.fields += `,${input.id}`;
						}
					}
				}
			});
		}
		
	});
	
	$(".modal").on("shown.bs.modal", function () {
		var buttonClasses = ['.removeRow', '.deleteVloga', '#removePodrocjeBtn'];
		for (const i in buttonClasses) {
			var item = document.querySelector(`#${this.id} ${buttonClasses[i]}`);
			if(item){
				item.focus();
				break;
			}
		}
	});
	$(document).on('click', '.removeRow', function(e){
		e.preventDefault();
		e.stopPropagation();
		this.blur();
		
		const tableroot = this.dataset.tableroot;
		const tableRow = this.dataset.tablerow;
		const selector = `table#${tableroot} tr.${tableRow}`;
		
		const table = document.querySelector(`table#${tableroot} tbody`);
		const childSumRow = table.querySelector('.sumRow');
		
		const numberOfRows = document.querySelectorAll(selector).length;
		if( numberOfRows <= 2 ){
			document.querySelector(`[data-bs-target="#remRow${tableRow}"]`).classList.add('hidden');
			$(`table#${tableroot} .removeThisRow`).addClass('disabled');
		}
		
		if ( numberOfRows > 1 ) {
			
			const addRowButton = document.querySelector(`.addRow[data-tablerow="${tableRow}"]`)
			addRowButton.dataset.interator -= 1;
			
			const allRows = document.querySelectorAll(`${selector}`);
			const rowToRemove = allRows[allRows.length - 1];
			
			if (childSumRow !== null ) {
				
				const allCols = rowToRemove.querySelectorAll('td');
				allCols.forEach(function (td, i) {
					const input = td.querySelector('input');
					if(input && (input.classList.contains('number') || input.classList.contains('percent'))){
						const currentCol = childSumRow.querySelectorAll('td')[i];
						if(currentCol){
							const inputSum = currentCol.querySelector('input.sum:not(.vmesni)');
							if(inputSum){
								inputSum.dataset.fields = inputSum.dataset.fields.replace(`,${input.id}`, '');
							}
							
							const inputSumVmesni = currentCol.closest('input.vmesni');
							if(inputSumVmesni){
								inputSumVmesni.dataset.fields = inputSumVmesni.dataset.fields.replace(`,${input.id}`, '');
							}
						}
					}
				});
			}
			
			$(`#remRow${tableRow} [data-bs-dismiss=modal]`).trigger('click');
			rowToRemove.remove();
			
			sumChanged();
			validateRestrictions();
		}
	});
	
	$(document).on("click", '.removeThisRow', function (e) {
		e.preventDefault();
		e.stopPropagation();
		
		var pressedButton = $(this);
		const modalTarget = $(pressedButton).data('modaltarget');
		
		const iterator = parseInt($(pressedButton).data('iterator'));
		
		const buttonModal = $(modalTarget).find('.removeThisRowModalBtn');
		buttonModal.attr('data-iterator', iterator);
		
		const iteratorHuman = $(modalTarget).find('.iteratorHuman');
		iteratorHuman.html(iterator + 1);
		
		$(modalTarget).modal('show');
		$(`.tooltip`).remove();
	});
	$(document).on('click', '.removeThisRowModalBtn', function(e){
		e.preventDefault();
		e.stopPropagation();
		this.blur();
		
		const tableroot = this.dataset.tableroot;
		const tableRow = this.dataset.tablerow;
		const iterator = parseInt(this.dataset.iterator);
		const selector = `table#${tableroot} tr.${tableRow}`;
		
		const table = document.querySelector(`table#${tableroot} tbody`);
		const childSumRow = table.querySelector('.sumRow');
		
		const numberOfRows = document.querySelectorAll(selector).length;
		
		if ( numberOfRows > 1 ) {
			
			$(`#remThisRowModal${tableRow} [data-bs-dismiss=modal]`).trigger('click');
			
			const addRowButton = document.querySelector(`.addRow[data-tablerow="${tableRow}"]`)
			addRowButton.dataset.interator -= 1;
			
			const allRows = document.querySelectorAll(`${selector}`);
			const rowToRemove = $(allRows[iterator]);
			const allRowsDown = $(rowToRemove).nextAll(); // select all rows down from this one
			const lastRow = allRows[allRows.length - 1];
			
			rowToRemove.remove();
			
			if (childSumRow !== null ) {
				
				const allCols = lastRow.querySelectorAll('td');
				allCols.forEach(function (td, i) {
					const input = td.querySelector('input');
					if(input && (input.classList.contains('number') || input.classList.contains('percent'))){
						const currentCol = childSumRow.querySelectorAll('td')[i];
						if(currentCol){
							const inputSum = currentCol.querySelector('input.sum:not(.vmesni)');
							if(inputSum){
								inputSum.dataset.fields = inputSum.dataset.fields.replace(`,${input.id}`, '');
							}
							
							const inputSumVmesni = currentCol.closest('input.vmesni');
							if(inputSumVmesni){
								inputSumVmesni.dataset.fields = inputSumVmesni.dataset.fields.replace(`,${input.id}`, '');
							}
						}
					}
				});
			}
			
			let curIterator = iterator;
			for(const row of allRowsDown){
				if(row.querySelector('input, select') !== null){
					
					const nextIterator = curIterator + 1;
					
					$(row).children('td.counter').html( nextIterator );
					
					const allDataIterators = $(row).find('[data-iterator]');
					allDataIterators.each(function () {
						$(this).attr('data-iterator', curIterator);
					});
					
					const allAttrIterators = $(row).find(`*`);
					allAttrIterators.each(function () {
						$.each(this.attributes, function() {
							if (this.value.includes(`_${ nextIterator }_`)) {
								const newValue = this.value.replace(`_${ nextIterator }_`, `_${ curIterator }_`);
								$(this.ownerElement).attr(this.name, newValue);
							}
						});
					});
					
					curIterator++;
				}
			}
			
			if ( numberOfRows <= 2 ) {
				$(`table#${tableroot} .removeThisRow`).addClass('disabled');
				document.querySelector(`[data-bs-target="#remRow${tableRow}"]`).classList.add('hidden');
			}
			
			sumChanged();
			validateRestrictions();
		}
	});
}


/*function vrednostProstovoljnoDelo(){
	if($('[id$="_vrsta_dela"]').length){
		$(document).on('change', '[id$="_vrsta_dela"]', function(e){
			const value = $(this).val();
			let output = null;
			switch (value) {
				case 'organizacijsko delo':
					output = 13;
					break;
				case 'vsebinsko delo':
					output = 10;
					break;
				case 'drugo prostovoljno delo':
					output = 6;
					break;
			}
			const inputVrednostUre = $(this).closest('tr').find('input[id$="_vrednost_ure"]');
			inputVrednostUre.val(output);
			$(inputVrednostUre).trigger("change");
		})
	}
}*/

function prilogeMetodaIzr(){
	const selectorSelect = 'select[id*="metoda_izr_"]';
	if($(selectorSelect).length){
		
		$(`table:has(${selectorSelect})`).each(function(index){
			setRequiredFiles(this, true);
		});
		
		$(document).on('change', selectorSelect, function(e){
			setRequiredFiles(this);
		});
		
		function setRequiredFiles( item, isTable = false ){
			const valueToRequire = 'Po priloženih dokumentih';
			
			let table = $(item).parents('table');
			if(isTable){
				table = item;
			}
			
			const allSelects = $(table).find(selectorSelect);
			const filesId = $(table).siblings('.files_wrapper').find('input[type="hidden"].isUploaded');
			
			let shouldBeReqired = false;
			for(const select of allSelects){
				if($(select).val() == valueToRequire){
					shouldBeReqired = true;
					break;
				}
			}
			
			toggleRequired(filesId, shouldBeReqired)
		}
	}
}

function showHideByCheckbox(){
	if($('.hideByCheckbox').length){
		$(document).on('change', '[id*="checkbox_"]', function(e){
			
			const checxId = $(this).attr('id').replace(/2$/, '');
			const hideByCheckboxElem = $(`.hideByCheckbox[data-checkboxid="${checxId}"]`);
			
			if(this.checked) {
				$(hideByCheckboxElem).addClass('show');
				$(hideByCheckboxElem).removeClass('hidden');
			}else{
				$(hideByCheckboxElem).removeClass('show');
				$(hideByCheckboxElem).addClass('hidden');
			}
			
			const requiredElements = $(hideByCheckboxElem).find('input[type="hidden"].isUploaded');
			for(const reqElem of requiredElements){
				toggleRequired(reqElem, this.checked)
			}
			
		});
	}
}

function toggleRequired( reqElem, isRequired = true,  ){
	if(isRequired) {
		$(reqElem).attr('required', true);
		//$(reqElem).addClass('required');
		$(reqElem).removeClass('requiredDisable');
	}else{
		$(reqElem).removeAttr('required');
		//$(reqElem).removeClass('required');
		$(reqElem).addClass('requiredDisable');
	}
}

function removeLoading(){
	$('.loadingWrapper').trigger('stopLoading');
}

$(function($){
	deleteClickTrigger();
	validateRestrictions();
	triggerNumChange();
	triggerInputChange();
	validateForm();
	//detectRequiredDrugoChange();
	addRow();
	datepickers();
	showHideByCheckbox();
	//vrednostProstovoljnoDelo();
	prilogeMetodaIzr();
	removeLoading();
});