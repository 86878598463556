import $ from "jquery";

import dtbs from "datatables.net-bs5";
import dtSl from './data-tables-sl.json';

function datatables(){
	
	let settings = {
		language: dtSl,
		stateSave: true,
		paging: false,
		info: false,
		responsive: true,
		language: {
			search: '_INPUT_',
			searchPlaceholder: 'Išči po vnosih'
		}
	}
	
	let table = null;
	if($('#submissions').length > 0){
		table = new dtbs('#submissions', {
			...settings,
			order: [],
			//order: [[1, 'ASC']],
			columnDefs: [{
				targets: -1,
				orderable: false
			},{
				targets: [0, 1], // Vlagatelj & Program
				width: '200px'
			}]
		});
	}
	
	if($('#scores').length > 0){
		table = new dtbs('#scores', {
			...settings,
			searching: false,
			order: [[0, 'ASC']],
			columnDefs: [{
				targets: -1,
				orderable: false
			},{
				targets: [0, 1], // Vlagatelj & Program
				width: '200px'
			},{
				targets: 'inputCol',
				orderDataType: 'dom-text',
				type: 'numeric',
				width: '80px'
			},{
				targets: 'sumCol',
				width: '80px'
			}
		]
		});
	}
	
	if($('#userslist').length > 0){
		table = new dtbs('#userslist', {
			...settings,
			columnDefs: [{
				targets: -1,
				orderable: false
			}]
		});
	}
	
	if($('#sessionlist').length > 0){
		table = new dtbs('#sessionlist', {
			...settings,
			columnDefs: [{
				targets: -1,
				orderable: false
			}]
		});
	}
		
	if($('#logslist').length > 0){
		table = new dtbs('#logslist', {
			language: dtSl,
			paging: false,
			info: false,
			responsive: true,
			ordering: false,
			pageLength: 100,
			//ajax: '/eva/logs/page',
			//processing: true,
			//serverSide: true
		});
	}
		
	if($('#docTemplates').length > 0){
		table = new dtbs('#docTemplates', {
			...settings,
			columnDefs: [{
				targets: -1,
				orderable: false,
				width: '80px'
			}]
		});
	}
	
	if($('#threeListWithButtons').length > 0){
		table = new dtbs('#threeListWithButtons', {
			...settings,
			columnDefs: [{
				targets: -1,
				orderable: false,
				width: '80px'
			}]
		});
	}
	
	$('#resetsort').on('click', function() {
		
		table.settings().order([]).draw()
		table.order([]).draw()
		
		window.location.reload();
	});
}

$(function(){
	datatables();
	
});
