import * as toasts from "../lib/toasts.js";
window.toasts = toasts;

import * as fnc from "./modules/functions.js";
window.fnc = fnc;

require(`./modules/general.js`);
require(`./modules/downloader.js`);
require(`./modules/uploadFile.js`);
require(`./modules/filters.js`);

require(`./modules/vlogaFunctions.js`);

const currentUrl = window.location.href;

if( currentUrl.includes('/register') ){
	require(`./modules/registration.js`);
}
if( currentUrl.includes('/evloge') ||
	currentUrl.includes('/login') ||
	currentUrl.includes('/register') ||
	currentUrl.includes('/pozabljeno-geslo') ||
	currentUrl.includes('/sprememba-gesla')
){
	require(`./modules/login.js`);
}

if( !(currentUrl.includes('/login') ||
	currentUrl.includes('/register') ||
	currentUrl.includes('/pozabljeno-geslo') ||
	currentUrl.includes('/sprememba-gesla'))
){
	require(`./modules/notlogin.js`);
}

if(currentUrl.includes('/eva')){
	require(`./modules/admin.js`);
}

if( currentUrl.includes('/vloga') ){
	require(`./modules/vloga.js`);
}

if( currentUrl.includes('/porocilo') || currentUrl.includes('/porocila') ){
	require(`./modules/porocilo.js`);
}

if(currentUrl.includes('/documents/edit')){
	require(`./modules/docxDocumentToHtml.js`);
}

if(currentUrl.includes('editor/') || currentUrl.includes('/editdata') || currentUrl.includes('/edit-razpis/') || currentUrl.includes('/edit-podrocje/') ){
	require(`./modules/tinymce.js`);
}