
const beforeUnloadListener = (event) => {
	event.preventDefault();
	save(false);
	return event.returnValue = "Ali ste prepričani, da želite končati urejanje?";
};

var unloadDefined = false;
function definePageUnload(){
	const currentUrl = window.location.href;
	if(!currentUrl.includes('/eva')){
		$(' #vloga input, #vloga textarea, #vloga select ').on('change', (event) => {
			if(!unloadDefined){
				addEventListener("beforeunload", beforeUnloadListener, {capture: true});
				unloadDefined = true;
			}
		});
	}
}

function save(showToast = true){
	if ($('form#vloga').hasClass("saveOsnutekVloga")) {
		var disabled = $('#vloga input:disabled').removeAttr('disabled');
		const formData = fnc.getFormData($('#vloga'));
		
		if(fnc.checkOneTab("Shranjevanje vloge je onemogočeno!")){
			return;
		}
		
		if(!fnc.allKeysEmpty(formData)){
			var formDataJson = JSON.stringify(formData);
			disabled.attr('disabled','disabled');
			let recordID = $('#vloga').data('dataid');
			let userId = $('#vloga').data('userid');
			
			$.post(`/evloge/vloga/${recordID}`, { 'data':formDataJson, 'userid':userId }, function(data){
				if(fnc.isJsonString(data)){
					var jsonData = JSON.parse(data);
					if(jsonData.error == false){
						
						if(showToast){
							toasts.show({
								message:'Vloga je bila shranjena kot osnutek.'
							});
						}
						if(unloadDefined){
							removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
						}
						$(document).trigger('saved');
					}else{
						if(jsonData.useriderror){
							toasts.show({
								message:`NAPAKA<br>
											Prijavljen uporabnik se ne sklada z vlogo!<br>
											Zaradi varnosti podatkov vloga ni bila shranjena!<br><br>
											Za odpravo napake se morate odjaviti in ponovno prijaviti.<br>
											Morebitne spremembe niso bile shranjene, zato si jih ročno shranite na vaš računalnik v beležnico!`,
								color: 'danger',
								time: 10000000
							});
						} else {
							toasts.show({
								message:'Napaka, vloga ni bila shranjena kot osnutek.',
								color: 'danger'
							});
						}
					}
				}else{
					console.log(data);
					toasts.show({
						message:'Napaka, vloga ni bila shranjena kot osnutek.',
						color: 'danger'
					});
				}
			})
		}
	}
}

function saveClickTrigger(){
	if($('#save').length){
		$('#save').on('click', function(e, triggerName){
			e.preventDefault();
			e.stopPropagation();
			
			let showToast = true;
			if(e.isTrigger && triggerName == 'uploadFile'){
				showToast = false;
			}
			save(showToast);
		})
	}
}

function timeSaveTrigger(){
	if($('#vloga').length && !$('form#vloga').hasClass("versionDisplay")){
		var interVal = setInterval(function(){
			$('#save').trigger('click');
		},60000);
	}
}

function signVloga(){
	$('#digitallySignVlogo').on('click', function(e){
		$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
	});
	
	document.addEventListener( "fileUploaded", (e) => {
		$('#oddajVlogo').removeClass('disabled').removeAttr('disabled');
		
		const formData = fnc.getFormData($('#izjaveForm'));
		
		if(fnc.allKeysEmpty(formData)){
			toasts.show({
				message:'Nobene izjave niste naložili!'
			});
			return;
		}
		
		var formDataJson = JSON.stringify(formData);
		
		let recordID = $('#izjaveForm').data('dataid');
		let userId = $('#izjaveForm').data('userid');
		
		$.post(`/evloge/vloga/${recordID}/izjave`, { 'data': formDataJson, 'userid': userId }, function(data){
			if(fnc.isJsonString(data)){
				var jsonData = JSON.parse(data);
				if(jsonData.error == false){
					
				}
			}
		})
		
	}, false);
	
	document.addEventListener( "fileDeleted", (e) => {
		
		const formData = fnc.getFormData($('#izjaveForm'));
		var formDataJson = JSON.stringify(formData);
		
		if( formDataJson === "{}" ){
			$('#oddajVlogo').addClass('disabled').attr('disabled', true);
			formDataJson = '';
		}
		
		let recordID = $('#izjaveForm').data('dataid');
		let userId = $('#izjaveForm').data('userid');
		
		$.post(`/evloge/vloga/${recordID}/izjave`, { 'data': formDataJson, 'userid': userId }, function(data){
			if(fnc.isJsonString(data)){
				var jsonData = JSON.parse(data);
				if(jsonData.error == false){
					
				}
			}
		})
		
	}, false);
	
	$('#oddajVlogo').on('click', function(e){
		$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
	});
}


function oddajDopolnitevVloge(){
	$('#oddajDopolnitevVloge').on('click', function(e){
		$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
		window.open(this.dataset.file , "_blank");
	});
}


function addSection() {
	$(document).on('click', '.addSection', function(e){
		e.preventDefault();
		e.stopPropagation();
		this.blur();
		
		const sectionRow = this.dataset.sectionrow;
		var interator = parseInt(this.dataset.interator) + 1;
		this.dataset.interator = interator;
		
		const section = document.querySelector( `.section#${sectionRow}` );
		
		const newId = sectionRow.replace(`_s0`, `_s${interator}`); // spremeni stevec sekcij
		
		var newSection = section.cloneNode(true);
		
		var newSectionHtml = newSection.outerHTML;
		newSectionHtml = newSectionHtml.replaceAll(sectionRow, newId); // replace section ID
		newSectionHtml = newSectionHtml.replaceAll(`_s0_`, `_s${interator}_`); // spremeni stevec polja
		newSectionHtml = newSectionHtml.replaceAll(/value="([^"]*)"/gim, ``); // Clear input values in the cloned row
		newSectionHtml = newSectionHtml.replaceAll(/selected="([^"]*)"/gim, ``);
		
		var wrapperDiv = document.createElement('div');
		wrapperDiv.innerHTML = newSectionHtml;
		var preparedRow = wrapperDiv.firstChild;
		
		
		const lastSectionRow = sectionRow.replace(`_s0`, `_s${interator-1}`);
		const lastSection = document.querySelector( `.section#${lastSectionRow}` );
		$(preparedRow).insertAfter(lastSection);
		
		var removeRowBtn = this.nextElementSibling;
		if (removeRowBtn) {
			removeRowBtn.classList.remove('hidden');
		}
		
	});
	
	$(document).on('click', '.removeSection', function(e){
		e.preventDefault();
		e.stopPropagation();
		this.blur();
		
		const sectionClass = this.dataset.sectionclass;
		const sectionRow = this.dataset.sectionrow;
		
		const numberOfRows = document.querySelectorAll(`.section.${sectionClass}`).length;
		if( numberOfRows <= 2 ){
			//this.classList.add('hidden');
			document.querySelector(`[data-bs-target="#remSec${sectionRow}"]`).classList.add('hidden');
		}
		
		if ( numberOfRows > 1 ) {
			
			const addSectionButton = document.querySelector(`.addSection[data-sectionrow="${sectionRow}"]`)
			addSectionButton.dataset.interator -= 1;
			
			const allSections = document.querySelectorAll(`.section.${sectionClass}`);
			const sectionToRemove = allSections[allSections.length - 1];
			
			$(`#remSec${sectionRow} [data-bs-dismiss=modal]`).trigger('click');
			sectionToRemove.remove();
		}
	});
}

function addProject() {
	$(document).on('click', '.addProject', function(e){
		e.preventDefault();
		e.stopPropagation();
		this.blur();

		 $("#includedContent").load("vloge/mladina_includes/new_projekt.html.twig");

		var removeRowBtn = this.nextElementSibling;
		if (removeRowBtn) {
			removeRowBtn.classList.remove('hidden');
		}
		
	});
	
}

var podrocjeDataId = null;
var podrocjeTarget = null;
const mutuallyExclusive = ["prireditev_vecja", "prireditev_mnozicna", "prireditev_mednarodna"];

function displayPodrocja(){
	if(document.querySelector('input[name^="podrocje"]')){
		
		mutuallyExclusive.forEach(function(checkboxId){
			const checkboxElement = document.getElementById(checkboxId);
			if(checkboxElement && checkboxElement.checked){
				mutuallyExclusive.forEach(function(disableId){
					if(disableId != checkboxId){
						$(`label[for="${disableId}"]`).addClass('disabled');
						$(`label[for="${disableId}"]`).attr('disabled', '');
						$(`#${disableId}`).attr('disabled', '');
					}
				});
			}
		});
		
		$('input[name^="podrocje"]').on('change', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			if(e.target.checked){
				
				var vlogaId = $('#vloga').data('id');
				var type = $(this).data('id');
				var podrocjeWrapper = $('.podrocje_wrapper[data-type="'+type+'"]');
				
				var canCheck = true;
				if( mutuallyExclusive.includes(type) ){
					mutuallyExclusive.forEach(function(checkboxId){
						if(checkboxId != type && document.getElementById(checkboxId).checked){
							canCheck = false;
						}
					});
					if(canCheck){
						mutuallyExclusive.forEach(function(checkboxId){
							if(checkboxId != type){
								$(`label[for="${checkboxId}"]`).addClass('disabled');
								$(`label[for="${checkboxId}"]`).attr('disabled', '');
								$(`#${checkboxId}`).attr('disabled', '');
							}
						});
					}
				}
				
				/*
				var key = 0; // enable key for dulicated podrocje
				$('.wrap_finance[data-type="'+type+'"]').each(function(e){
					key++;
				});
				*/
				
				if(canCheck){
					$.get('/evloge/vloga/'+vlogaId+'/'+type+'', {/*'key':key,*/ 'showsection':true}, function(data){
						if(data != 'error'){
							//podrocjeWrapper.append(data);
							podrocjeWrapper.html(data);
							triggerFieldChangeMultiplyContains();
						}
					});
					//$('.dokazila_wrapper[data-type="'+type+'"]').addClass('show');
					
				}else{
					e.target.checked = false;
				}
				
			}else{
				/*if(confirm('Želite odstraniti celotno področje? \nPo tem dejanju ni mogoče več povrniti izbrisanih podatkov!')){
					
					$('.podrocje_wrapper[data-type="'+$(this).data('id')+'"] .insideWrap').remove();
					triggerFieldChangeMultiplyContains();
					
				} else {
					e.target.checked = true;
				}*/
				
				e.target.checked = true;
				const siblingText = $($(this).parent().siblings()[0]).text();
				$(`#removePodrocjeLabel span`).text(siblingText);
				$(`#removePodrocje`).modal('show');
				
				podrocjeDataId = $(this).data('id');
				podrocjeTarget = e.target;
				
				modalConfirm(function(confirm){
					if(confirm){
						podrocjeTarget.checked = false;
						$('.podrocje_wrapper[data-type="'+podrocjeDataId+'"] .insideWrap').remove();
						
						mutuallyExclusive.forEach(function(checkboxId){
							$(`label[for="${checkboxId}"]`).removeClass('disabled');
							$(`label[for="${checkboxId}"]`).prop("disabled", false);
							$(`#${checkboxId}`).prop("disabled", false);
							
						});
						
					}
					$("#removePodrocje [data-bs-dismiss=modal]").trigger('click');
				});
			}
		});
		
		var modalConfirm = function(callback){
			$("#removePodrocjeBtn").on("click", function(){
				callback(true);
			});
		};
	}
}

function displayProjekt(){
	const allSelects = document.querySelectorAll('select[id^=stevilo_projektov]');
	if(allSelects.length > 0){
		for( var i = 0; i < allSelects.length; i++ ){
			
			let prevVal = parseInt(allSelects[i].value);
			const parent = allSelects[i].closest('.numberProjects');
			const leto = parent.dataset.leto;
			
			$(allSelects[i]).on('change', function(e){
				e.preventDefault();
				e.stopPropagation();
				
				const selectField = this;
				const newVal = parseInt(this.value);
				const difference = newVal - prevVal;
				
				const rootId = 'projektWrapper';
				if(difference != 0){
					if(difference > 0){ // dodal
						var vlogaId = $('#vloga').data('id');
						
						let selector = `#${rootId}_${newVal}`;
						let params = { 'key': newVal };
						
						const minKey = parseInt(prevVal) + 1;
						const maxKey = newVal;
						
						for(var key = minKey; key <= maxKey; key++ ){
							
							if(leto){
								params['leto'] = leto;
								selector = `#${rootId}_${leto}_${newVal}`;
							}
							
							const wrapper = document.querySelector(selector);
							
							const template = wrapper.dataset.template;
							params['template'] = template;
							
							$.get('/evloge/vloga/'+vlogaId+'/addprojekt/', params, function(data){
								if(data != 'error'){
									wrapper.innerHTML = data;
								}
							});
						}
						
						prevVal = newVal;
					} else { // odvzel
						$(`#remProject`).modal('show');
						modalConfirm(function(){
							const maxKey = parseInt(prevVal);
							const minKey = newVal;
							
							for(var key = maxKey; key > minKey; key-- ){
								let selector = `#${rootId}_${key}`;
								if(leto){
									selector = `#${rootId}_${leto}_${key}`;
								}
								document.querySelector(selector).innerHTML = '';
							}
							prevVal = newVal;
							$("#remProject [data-bs-dismiss=modal]").trigger('click');
						});
						modalCancel(function(cancelBtn){
							selectField.value = prevVal;
							if(cancelBtn){
								$("#remProject [data-bs-dismiss=modal]").trigger('click');
							}
						});
					}
				}
			});
			var modalConfirm = function(callback){
				$("#removeProjectBtn").on("click", function(){
					callback();
				});
			};
			var modalCancel = function(callback){
				$("#cancelRemoveProjectBtn").on("click", function(){
					callback(true);
				});
				$("#remProject .btn-close").on("click", function(){
					callback(false);
				});
			};
		}
	}
}


var sklopDataId = null;
var sklopTarget = null;
function displaySklop(){
	if(document.querySelector('input[name^="sklop"]')){
		
		$('input[name^="sklop"]').on('change', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			if(e.target.checked){
				var vlogaId = $('#vloga').data('id');
				const parentCheckboxes = this.closest('.section.checkmarks');
				const template = parentCheckboxes.dataset.template;
				let key = parentCheckboxes.dataset.key;
				key = !key ? 0 : key++;
				parentCheckboxes.dataset.key = key;
				
				sklopDataId = this.dataset.id;
				const title = this.closest('.inputGroup').getElementsByTagName('p')[0].innerHTML; // get title of checkbox field
				
				const leto = parentCheckboxes.dataset.leto;
				
				$.get('/evloge/vloga/'+vlogaId+'/addprojekt/', { 'key': sklopDataId, 'template': template, 'sklop': sklopDataId, 'title':title, 'leto':leto }, function(data){
					if(data != 'error'){
						document.querySelector(`.sklop_wrapper[data-type="${sklopDataId}"]`).innerHTML = data;
					}
				});
				
			}else{
				e.target.checked = true;
				const siblingText = $($(this).parent().siblings()[0]).text();
				$(`#removePodrocjeLabel span`).text(siblingText);
				$(`#removePodrocje`).modal('show');
				
				sklopDataId = $(this).data('id');
				sklopTarget = e.target;
				
				modalConfirm(function(confirm){
					if(confirm){
						sklopTarget.checked = false;
						document.querySelector(`.sklop_wrapper[data-type="${sklopDataId}"]`).innerHTML = '';
					}
					$("#removePodrocje [data-bs-dismiss=modal]").trigger('click');
				});
			}
		});
		
		var modalConfirm = function(callback){
			$("#removePodrocjeBtn").on("click", function(){
				callback(true);
			});
		};
	}
}

$(function($){
	saveClickTrigger();
	timeSaveTrigger();
	
	definePageUnload();
	
	signVloga();
	oddajDopolnitevVloge();
	
	addSection();
	addProject();
	
	displayPodrocja();
	displayProjekt();
	displaySklop();
	
});