
function generateId(length = 8, prefix = '') {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let generatedId = prefix;
	for (let i = 0; i < length; i++) {
		generatedId += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return generatedId;
}

function allKeysEmpty(obj) {
	for (const key in obj) {
		if (obj.hasOwnProperty(key) && obj[key] !== "") {
			return false;
		}
	}
	return true;
}

function getFormData($form, prefix = ''){
	const data = new FormData($form[0]);
	const values = Object.fromEntries(data.entries());
	if(prefix != ''){
		for (const item in values){
			if(!item.startsWith(prefix)){
				delete values[item];
			}
		}
	}
	return values;
}

function isJsonString(str) {
	try {
		var json = JSON.parse(str);
		return (typeof json === 'object');
	} catch (e) {
		return false;
	}
}

function numberWithCommas(x, min = 2, max = 2) {
	return x.toLocaleString('sl-SI', { minimumFractionDigits: min, maximumFractionDigits: max });
}

function parseDateWithTime(dateString) {
	// Split the string into its components (day, month, year, hour, minute)
	const parts = dateString.split(" ");
	const [day, month, year, time] = parts;
	
	// Split the time part into hour and minute
	const [hour, minute] = time.split(":");
	
	// Create a Date object with individual components
	return new Date(year, month - 1, day, hour, minute);
}

function formatDate(date) {
	const day = date.getDate();
	const month = date.getMonth()+1;
	const year = date.getFullYear();
	return `${day}. ${month}. ${year}`;
}

function parseNumber(element){
	let value = String($(element).val());
	if(value !== ''){
		if($(element).hasClass('whole')){
			value = value.replace(/[^0-9]*/g, ''); // remove all text, leave only numbers
			value = parseInt(value);
		}else{
			value = value.replace(/[^0-9,]*/g, ''); // remove all text, leave only numbers and commas
			value = value.replaceAll(',','.');
			value = parseFloat(value);
		}
	}
	if(isNaN(value)){
		value = 0;
	}
	return value;
}

function appendTextWithBR(text, appendString){
	if(text != ''){
		text += '<br/>'
	}
	text += appendString;
	
	return text;
}

function findDuplicateLinks(dict) {
	const seenLinks = new Set();
	const duplicates = [];

	for (const key in dict) {
		const link = dict[key];
		if (seenLinks.has(link)) {
			duplicates.push(link); // Store duplicate link
		} else {
			seenLinks.add(link); // Add to seen links
		}
	}

	return duplicates;
}

function checkOneTab(text = ''){
	
	if (parseInt(localStorage.getItem('tabCount'), 10) > 1) {
		
		if(text == ''){
			text = 'Nekatere funkcije sistema so onemogočene!';
		}
		const alone = localStorage.getItem("alone");
		const getTabLinks = JSON.parse(localStorage.getItem('tabLinks'));
		
		if(alone === 'yes'){
			const thisTabId = sessionStorage.getItem("tabId");
			for(const tabId in getTabLinks){
				if(thisTabId != tabId){
					delete getTabLinks[tabId];
				}
			}
			localStorage.setItem('tabLinks', JSON.stringify(getTabLinks));
			localStorage.setItem('tabCount', Object.keys(getTabLinks).length);
		}
		
		const duplicates = findDuplicateLinks(getTabLinks);
		const pathName = window.location.pathname;
		
		// check if same tabs open
		if ( duplicates.includes(pathName) ){
			toasts.show({
				message:`<div class="biggerText">Odprtih imate več zavihkov!<br>${text}<br>Nemudoma zaprite ostale zavihke!</div>`,
				color: 'danger',
				time: 10000000,
				type: 'moreTabs'
			});
			return true;
		}
	}
	
	toasts.hideByType({type: 'moreTabs'});
	return false;
}

function removeQueryParam(param) {
	// Create a URL object from the current location
	const url = new URL(window.location.href);
	
	// Delete the specified parameter
	url.searchParams.delete(param);
	
	// Update the browser's history with the new URL
	window.history.pushState({}, '', url);
}

module.exports = {
	generateId,
	allKeysEmpty,
	getFormData,
	isJsonString,
	numberWithCommas,
	parseDateWithTime,
	parseNumber,
	appendTextWithBR,
	formatDate,
	checkOneTab,
	removeQueryParam,
}
