
function deleteUser(){
	$(document).on('click', '#deleteUserBtn', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const userId = this.dataset.userid;
		
		$.post(`/eva/users/delete/${userId}`, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(!jsonData.error){
				window.location.href = `/eva/users`;
			}
		});
	});
}

function saveFileAdmin(){
	$(document).on('click', '.saveFileAdmin', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const fileId = this.dataset.fileid;
		const title = $(`#fileName_${fileId}`).val();
		
		$.get('/eva/editdata/files/'+fileId+'', { 'title': title }, function(data){
			if(data != 'error'){
				window.location.reload();
			}else{
				console.log(data);
				toasts.show({
					message:'Napaka!',
					color: 'danger'
				});
			}
		});
	});
}

function deleteFileAdmin(){
	$(document).on('click', '.deleteFileAdmin', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const fileId = this.dataset.fileid;
		if(confirm("Ali res želiš izbrisati datoteko?")){
			$.get('/eva/editdata/files/delete/'+fileId+'', { }, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				
				if(data != 'error'){
					window.location.reload();
				}else{
					toasts.show({
						message:'Napaka pri izbrisu datoteke!',
						color: 'danger'
					});
				}
			});
		}
	});
}

function reorderFilesAdmin(){
	$(document).on('change', '.fileOrder', function(e){
		const newValue = this.value;
		/*
		let prevItem = 0;
		const allOrder = $('.fileOrder');
		for(const item of allOrder){
			if(item == this){
				continue;
			}
			if(item.value == newValue){
				$(item).val(parseInt(item.value) - 1 );
			}
			if(prevItem == item.value){
				$(item).val(parseInt(item.value) + 1);
			}
			prevItem = item.value;
		}
		*/
	});
	
	$(document).on('click', '#reorderFilesAdmin', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const vlogaId = this.dataset.vlogaid;
		let order = {};
		
		$('.fileOrder').each(function( index ) {
			order[this.dataset.fileid] = $(this).val();
		});
		$.get('/eva/editdata/files/reorder/'+vlogaId+'', { 'order': JSON.stringify(order) }, function( data ){
			if(data != 'error'){
				window.location.reload();
			}else{
				toasts.show({
					message:'Napaka!',
					color: 'danger'
				});
			}
		});
	});
}

const uploadFileAdmin = function(file, vlogaId) {
	
	const request = new XMLHttpRequest();
	request.upload.onload = () => {
		
	}
	request.upload.onerror = () => {
		toasts.show({
			message:'Napaka pri nalaganju datoteke',
			color: 'danger',
			time: 10000
		});
	}
	request.upload.onabort = () => {
		toasts.show({
			message:'Nalaganje je bilo preklicano',
			color: 'danger',
			time: 10000
		});
	}
	request.onreadystatechange = () => {
		if (request.readyState === 4 && request.status === 200) {
			if(!fnc.isJsonString(request.responseText)){
				console.log(request.responseText);
			}
			
			var jsonData = JSON.parse(request.responseText);
			
			if(!jsonData.error){ // success
				window.location.reload();
			} else {
				toasts.show({
					message:jsonData.error,
					color: 'danger',
					time: 10000
				});
			}
		}
	};
	
	const API_ENDPOINT = "/eva/editdata/files/upload/"+vlogaId;
	request.open("POST", API_ENDPOINT, true);
	
	const formData = new FormData();
	formData.append("file", file);
	request.send(formData);
};

function defineUploadAdmin(){
	
	$(document).on('click', '.fileDropZone', function(e){
		e.preventDefault();
		e.stopPropagation();
		const fileUploadInputId = this.dataset.fileuploadinputid;
		const input = document.getElementById(fileUploadInputId);
		if(input){
			input.click();
		}
	});
	
	$(document).on("change", 'input[type="file"]#uploadAdmin', function(e){
		processFilesAdmin([...e.target.files], $(this));
	});
	
	$(document).on('dragover', '.fileDropZone', function(e){
		e.preventDefault();
		e.stopPropagation();
	});
	
	$(document).on('drop', '.fileDropZone', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		let dropZoneEl = e.target;
		if(dropZoneEl.tagName === 'SPAN'){
			dropZoneEl = dropZoneEl.parentElement;
		}
		const fileUploadInputId = dropZoneEl.dataset.fileuploadinputid;
		
		processFilesAdmin([...e.originalEvent.dataTransfer.files], $(`#${fileUploadInputId}`));
	});
}

const processFilesAdmin = (files, parent) => {
	const vlogaid = parent.data('vlogaid');
	for (const file of files) {
		uploadFileAdmin(file, vlogaid);
	}
};

function listKomisije(){
	$(document).on('click', '.addkomisija', function(e){
		e.preventDefault();
		var vlogaId = $(this).data('id');
		var list = $('#listkomisije option:selected');
		var name = list.text();
		var id = list.val();
		if(id != ''){
			$('.listkomisija ul').append(`<li><button type="button" class="btn btn-delete removekomisija" data-vloga="${vlogaId}" title="Odstrani člana komisije" data-toggle="tooltip"></button> ${name}<input type="hidden" class="clanikomisije" name="clanikomisije[]" value="${id}"></li>`);
			list.attr('disabled', true);
			$('#listkomisije option[value=""]').attr('selected',true);
		}
	});
	$(document).on('click', '.removekomisija', function(){
		var parentLine = $(this).parent('li');
		var clanId = $(this).parent('li').find('input').val();
		parentLine.remove();
		$('#listkomisije option[value="'+clanId+'"]').attr('disabled', false);
	});
	$(document).on('click', '#savekomisija', function(){
		var vlogaId = $(this).data('vloga');
		var formData = [];
		$('.clanikomisije').each(function(){
			formData.push($(this).val());
		});
		$.post(`/eva/vloga/${vlogaId}/assign`, { 'clanikomisije[]' : formData }, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(!jsonData.error){
				window.location.reload();
			}
		});
	});
}

function zacniTockovanje(){
	const selector = '#zacniTockovanje';
	if( $(selector).length > 0 ){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			$("#modalTockovanje [data-bs-dismiss=modal]").trigger('click');
			$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
			
			let vlogaID = $('#mainList').data('id');
			$.post(`/eva/vloga/${vlogaID}/startscoring`, { }, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				if(data.length > 5 && !jsonData.error){
					/*toasts.show({
						message:'Na vlogi je bil sprožen proces točkovanja.',
						time: 100000
					});*/
					window.location.reload();
				}else{
					toasts.show({
						message: jsonData.message ? jsonData.message : 'Na začetku procesa točkovanja je prišlo do težave!',
						color: 'danger'
					});
				}
				$('.loadingWrapper').removeClass('opacity-loading').trigger('stopLoading');
			})
			
		})
	}
}

function getFormDataAdmin($form){
	const data = new FormData($form[0]);
	var values = {};
	for (var [key, value] of data.entries()) {
		values[key] = value;
	}
	return values;
}

function numbersWithPoint(values){
	let valuesNew = {};
	for (const variable in values){
		valuesNew[variable] = values[variable];
		if (valuesNew[variable].includes(',')) {
			valuesNew[variable] = valuesNew[variable].replace(/\./g, '').replace(/,/g, '.');
		}
	}
	return valuesNew;
}

var sentToserver = {};
function processFormData(selector, keyName) {
	const data = getFormDataAdmin($(selector));
	if (Object.keys(data).length !== 0) {
		sentToserver[keyName] = JSON.stringify(numbersWithPoint(data));
	}
}

function detectInputed( parent ){
	
	var allFilled = true;
	var inputFields = $(parent).find('input:visible, select:visible, textarea:visible, [id$="potrdiInterpret"]');
	for (var i = 0; i < inputFields.length; i++) {
		if (( inputFields[i].value === "" && !$(inputFields[i]).hasClass('notrequired') ) || $(inputFields[i]).hasClass('is-invalid') ){
			allFilled = false;
		}
	}
	return allFilled;
}

function setInvalidField( parent ){
	
	let allFilled = true;
	const inputFields = parent.querySelectorAll('input, select, textarea');
	
	inputFields.forEach(function(element){
		$(element).removeClass('is-invalid');
		$(element).siblings(`.invalid-feedback`).remove();
		
		if(element.value == ''){
			allFilled = false;
			$(element).addClass('is-invalid');
			$(element).after(`<div class="invalid-feedback">Vnosno polje ni izpolnjeno!</div>`);
		}
	});
	return allFilled;
}

function scoringPage(){
	
	var interVal = setInterval(function(){
		saveData();
	}, 60000); // save once per minute
	var sentToserver = {};
	function saveData(){
		if($('.scoring .scoringPage').length > 0){
			savePoints();
		}
	}
	
	const processFormData = (selector, key) => {
		const data = numbersWithPoint( getFormDataAdmin($(selector)));
		if (Object.keys(data).length > 0) {
			sentToserver[key] = JSON.stringify(data);
		}
	};
	
	function savePoints(){
	
		const recordID = [
			$('#values_data').data('dataid'),
			$('#values_skrbnik').data('dataid'),
			$('#values_komisija').data('dataid'),
		].find(Boolean); // finds first element that is valid (exists)
		
		processFormData('#values_data', 'values_data');
		processFormData('#values_skrbnik', 'skrbnik_data');
		processFormData('#values_komisija', 'komisija_data');
		
		$.post(`/eva/vloga/${recordID}/savescoring`, sentToserver, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(data.length > 5 && !jsonData.error){
				/* toasts.show({
					message:'Točke so bile vmesno zabeležene.'
				}); */
			}else{
				toasts.show({
					message: 'Zaradi napake pri shranjevanju točk te niso bile shranjene.',
					color: 'danger'
				});
			}
		})
	}
	
	var getWordCount = function(v){
		var matches = v.match(/\S+/g) ;
		return matches?matches.length:0;
	}
	function maxNumberOfChars( textarea ){
		let tooMuch = false;
		if($(textarea).data("maxwords")){
			const maxWords = $(textarea).data("maxwords");
			if(getWordCount($(textarea).val()) > maxWords){
				tooMuch = true;
			}
		}
		if($(textarea).data("maxchars")){
			const maxchars = $(textarea).data("maxchars");
			if($(textarea).val().length > maxchars){
				tooMuch = true;
			}
		}
		return tooMuch;
	}
	
	function enableNextButton(currentPage, parent, focus = false){
		const lastPage = $('.scoringPage').last().data('page');
		if(currentPage === lastPage){
			$('#finishScoring').removeAttr('disabled');
		}else{
			parent.find('.next').removeAttr('disabled');
			if(focus){
				parent.find('.next').focus();
			}
		}
	}
	function disableNextButton(currentPage, parent){
		const lastPage = $('.scoringPage').last().data('page');
		if(currentPage === lastPage){
			$('#finishScoring').prop( "disabled", true );
		}else{
			parent.find('.next').prop( "disabled", true );
		}
	}
	
	const parent = $('.scoringPage:not(".hiddenPage")');
	if(detectInputed(parent)){
		enableNextButton($(parent).data('page'), parent);
	}
	
	$('.scoringPage .form-control').on("change keyup paste click", function() {
		const parent = $(this).parents('.scoringPage');
		
		let wrongInput = false;
		let text = '';
		
		// detect original value
		var orgVal = $(this).data('orgval');
		var newVal = $(this).val();
		$(this).tooltip('dispose');
		if(orgVal != newVal && orgVal != '' ){
			$(this).removeClass('originalValue');
			$(this).addClass('changedValue');
			$(this).attr('title', 'Vrednost je bila spremenjena.');
			$(this).tooltip();
		}else{
			$(this).addClass('originalValue');
			$(this).removeClass('changedValue');
			$(this).attr('title', '');
		}
		
		if($(this).is("input") && !$(this).hasClass("text")){
			let value = $(this).val();
			if( value !== '' ){
				if($(this).attr('data-float') == 'true'){
					value = value.replace(/[^0-9.,]*/g, ''); // leave only numbers!
					//value = parseFloat(value);
				}else{
					value = value.replace(/[^0-9]*/g, ''); // leave only numbers!
					value = parseInt(value);
				}
				$(this).val( isNaN(value) ? '' : value );
			}
			if($(this).attr('data-scoremax')){
				const max = parseInt($(this).data('scoremax'));
				if(value > max){
					wrongInput = true;
					text = `Vnesena vrednost ne sme biti večja od ${max}!`;
				}
			}
			if($(this).attr('data-scoremin')){
				const min = parseInt($(this).data('scoremin'));
				if(value < min){
					wrongInput = true;
					text = `Vnesena vrednost ne sme biti manjša od ${min}!`;
				}
			}
		}
		if($(this).is("textarea")){
			if(maxNumberOfChars($(this))){
				wrongInput = true;
				
				if($(this).data("maxwords")){
					const maxWords = $(this).data("maxwords");
					text = `Vnosno polje je omejeno na ${maxWords} besed! Skrajšajte vaše besedilo.`;
				}
				if($(this).data("maxchars")){
					const maxchars = $(this).data("maxchars");
					text = `Vnosno polje je omejeno na ${maxchars} znakov! Skrajšajte vaše besedilo.`;
				}
			}
		}
		
		$(this).removeClass('is-invalid');
		$(this).siblings(`.invalid-feedback`).remove();
		
		if(wrongInput){
			$(this).addClass('is-invalid');
			$(this).after(`<div class="invalid-feedback">${text}</div>`);
			disableNextButton($(parent).data('page'), parent);
		}else{
			if(detectInputed( parent )){
				enableNextButton($(parent).data('page'), parent);
			}else{
				disableNextButton($(parent).data('page'), parent);
			}
		}
	});
	
	$('.scoringPage button.potrdiInterpret').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const dataId = $(this).data('id');
		const parent = $(this).parents('.scoringPage');
		
		const select = parent.find('select');
		let value = '';
		if(select){
			value = select.val();
			const idInput = select.attr('id');
			$(`[id="${idInput}_input"]`).val(value); // because of dot in id, it is necessary to use attr selector
		}else{
			parent.find('input').attr('readonly', true);
			value = parent.find('input').val();
		}
		
		$(select).removeClass('is-invalid');
		$(select).siblings(`.invalid-feedback`).remove();
		
		if( value != '' ){
			$(this).attr('disabled', true);
			$(this).text('POTRJENO');
			$(`[id="${dataId}"]`).val('true'); // because of dot in id, it is necessary to use attr selector
			
			enableNextButton($(parent).data('page'), parent, true);
			saveData();
		}else{
			const text = 'Polje je prazno!'
			$(select).addClass('is-invalid');
			$(select).after(`<div class="invalid-feedback">${text}</div>`);
			disableNextButton($(parent).data('page'), parent);
		}
	});
	
	$('.scoringPage select.selectPotrdi').on('change',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const idInput = $(this).attr('id');
		$(`[id="${idInput}_potrdiInterpret"]`).val('');
		$(`[id="${idInput}_input"]`).val($(this).val());
		
		const parent = $(this).parents('.scoringPage');
		
		const button = $(parent).find("button.potrdiInterpret");
		$(button).attr('disabled', false);
		$(button).text('POTRDI');
		
		disableNextButton($(parent).data('page'), parent);
	});
	
	$('.buttonsScoring button.next').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const parent = $(this).parents('.scoringPage');
		
		if(detectInputed( parent )){
			
			saveData();
			
			parent.addClass('hiddenPage');
			
			const page = parent.data('page');
			const nextPage = page+1;
			
			const nextPageElement = $(`.scoringPage.page${nextPage}`);
			nextPageElement.removeClass('hiddenPage');
			
			if(detectInputed(nextPageElement)){
				enableNextButton(nextPage, nextPageElement);
			}
			
			const elementToFocus = $(nextPageElement).find('input,textarea,select').filter(':visible:first');
			$(elementToFocus).focus(); // SET FOCUSED first element
			
			scrollToSection(nextPageElement);
		}else{
			toasts.show({
				message:'Ni mogoče nadaljevati, saj niste ustrezno izpolnilnili vnosnih polj!',
				color: 'danger'
			});
		}
	});
	
	var currentSection = null;
	function scrollToSection(pageElement){
		const idElement = pageElement.data('scroll');
		if( idElement !== currentSection){
			if( idElement != '' && idElement != null && $(`#${idElement}`).length > 0 ){
				$('html, body').animate({
					scrollTop: $(`#${idElement}`).offset().top-(window.innerHeight/2)
				}, 500);
			}
			currentSection = idElement;
		}
	}
	//scrollToSection($(`.scoringPage.page0`));
	
	$('.buttonsScoring button.prev').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const parent = $(this).parents('.scoringPage');
		parent.addClass('hiddenPage');
		
		const page = parent.data('page');
		const nextPage = page-1;
		
		const nextPageElement = $(`.scoringPage.page${nextPage}`);
		nextPageElement.removeClass('hiddenPage');
		scrollToSection(nextPageElement);
	});
	
	$('#finishScoringAfterModal').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		saveData();
		
		const idVloge = [
			$('#values_data').data('id'),
			$('#values_skrbnik').data('id'),
			$('#values_komisija').data('id'),
		].find(Boolean);
		const dataId = [
			$('#values_data').data('dataid'),
			$('#values_skrbnik').data('dataid'),
			$('#values_komisija').data('dataid'),
		].find(Boolean);
		
		const iteration = $('#values_komisija').data('iteration');
		
		$.post(`/eva/vloga/${dataId}/finishscoring`, { 'iteration': iteration }, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(jsonData.error == false){
				window.location.href = `/eva/vloga/${idVloge}`;
			} else {
				toasts.show({
					message:'Prišlo je do napake pri zaključevanju točkovanja!',
					color: 'danger'
				});
			}
		})
	});
	
}

function saveDocumentsData(){
	
	$('#saveSklepData').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		savePogodbaData();
	});
	
	$('#saveOdlocbaData').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const inputedAll = setInvalidField(document.querySelector('.mainList'));
		
		if( !inputedAll ) {
			toasts.show({
				message:'Izpolniti morate vsa vnosna polja!',
				color: 'danger'
			});
		} else {
			saveOdlocbaData();
			const vlogaDataId = $(this).data('dataid');
			window.location.href = `/eva/editor/${vlogaDataId}`
		}
	});
	
	$('#savePogodbaData').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const inputedAll = setInvalidField(document.querySelector('.mainList'));
		
		if( !inputedAll ) {
			toasts.show({
				message:'Izpolniti morate vsa vnosna polja!',
				color: 'danger'
			});
		} else {
			savePogodbaData();
			const vlogaDataId = $(this).data('dataid');
			window.location.href = `/eva/editor/${vlogaDataId}`
		}
		
	});
	
	function saveOdlocbaData(){
	
		const recordID = $('#odlocba_data').data('dataid');
		
		processFormData('#odlocba_data_common', 'odlocba_data_common'); // za vse odlocbe skupno -> gre v tabelo vloga
		processFormData('#odlocba_data_vloga', 'odlocba_data_vloga'); // za to odlocbo skupno -> gre v tabelo vloga_data
		processFormData('#odlocba_data', 'odlocba_data'); // za vsak program te odlocbe -> gre v tabelo calculation ali v vloga_data -> odvisno če obstaja calculation
		
		$.post(`/eva/vloga/${recordID}/saveodlocbadata`, sentToserver, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(data.length > 5 && !jsonData.error){
				toasts.show({
					message:'Podatki odločbe so bili shranjeni.'
				});
			}else{
				toasts.show({
					message: 'Zaradi napake pri shranjevanju podatki odločbe niso bili shranjeni.',
					color: 'danger'
				});
			}
		})
	}
	
	function savePogodbaData(){
	
		const recordID = $('#pogodba_data').data('dataid');
		
		processFormData('#pogodba_data', 'pogodba_data');
		
		$.post(`/eva/vloga/${recordID}/savepogodbadata`, sentToserver, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(data.length > 5 && !jsonData.error){
				toasts.show({
					message:'Podatki pogodbe so bili shranjeni'
				});
			}else{
				toasts.show({
					message: 'Zaradi napake pri shranjevanju podatki pogodbe niso bili shranjeni.',
					color: 'danger'
				});
			}
		})
	}
}

function commissionFinish(){
	
	$('#commissionFinishModal').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const vlogaId = $(this).data('id');
		const userId = $(this).data('userid');
		const iteration = $(this).data('iteration');
		
		$.post(` /eva/vloga/${vlogaId}/commissionfinish/${userId}`, { 'iteration':iteration }, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(!jsonData.error){
				window.location.href = `/eva`;
			}else{
				toasts.show({
					message: 'Zaradi napake točkovanje ni bilo zaključeno.',
					color: 'danger'
				});
			}
		});
	});
	
}

function closeSupplButton(){
	if( $('#submissions').length > 0 ){
		$('.closeSupplButton').on('click', function(e){
			var vlogaId = $(this).data( 'dataid' );
			$("#endSupplButton").attr( "data-dataid", vlogaId );
		});
		
		$('#endSupplButton').on('click',function(e){
			e.preventDefault();
			e.stopPropagation();
			
			const recordID = $(this).data('dataid');
			
			$.post(`/eva/reject/${recordID}`, { 'rejectType': 'sklep' }, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				if(data.length > 5 && !jsonData.error){
					window.location.reload();
				}else{
					toasts.show({
						message:'Pri označevanju vloge kot zavrnjene s sklepom je prišlo do težave!',
						color: 'danger'
					});
				}
			});
		});
	}
}

function returnButton(){
	if( $('#submissions').length > 0 ){
		$('.returnButton').on('click', function(e){
			var vlogaId = $(this).data( 'dataid' );
			$("#returnModalButton").attr( "data-dataid", vlogaId );
		});
		
		$('#returnModalButton').on('click',function(e){
			e.preventDefault();
			e.stopPropagation();
			
			const recordID = $(this).data('dataid');
			
			$.post(`/eva/return/${recordID}`, {  }, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				if(data.length > 5 && !jsonData.error){
					window.location.reload();
				}else{
					toasts.show({
						message:'Pri povrnitvi vloge v izpolnjevanje je prišlo do napake!',
						color: 'danger'
					});
				}
			});
		});
	}
}


function saveKomisijaPopravki(){
	
	const selector = '#saveKomisijaPopravki';
	if( $(selector).length > 0 && $(selector).attr('disabled') === undefined ){
		
		$(selector).on('click',function(e){
			e.preventDefault();
			e.stopPropagation();
			
			saveVrstice();
		});
		
		var saveVrsticeAutomatic = setInterval(function(){
			saveVrstice();
		}, 60000); // save once per minute
		
		function validateNumbers(element){
			const value = $(element).val();
			let inputOK = true;
			let text = '';
			
			if($(element).attr('max')){
				const max = parseInt($(element).attr('max'));
				if(value > max){
					inputOK = false;
					text = `Vnesena vrednost ne sme biti večja od ${max}!`;
				}
			}
			if($(element).attr('min')){
				const min = parseInt($(element).attr('min'));
				if(value < min){
					inputOK = false;
					text = `Vnesena vrednost ne sme biti manjša od ${min}!`;
				}
			}
			$(element).removeClass('is-invalid');
			$(element).siblings(`.invalid-feedback`).remove();
			
			if(!inputOK){
				$(element).addClass('is-invalid');
				$(element).after(`<div class="invalid-feedback">${text}</div>`);
			}
			return inputOK;
		}
		
		$('.rowInputsCommissionEdit input, .rowInputsCommissionEdit select').on('change paste keyup',function(e){ // calculate SUM on the fly
			const inputs = $(this).parent().parent().find('.input input, .input select');
			let sum = 0;
			let sum1 = 0;
			let sum2 = 0;
			inputs.each(function( index ) {
				
				let value = $(this).val();
				value = value.replace(/[^0-9]*/g, ''); // leave only numbers!
				value = parseInt(value);
				$(this).val(value);
				
				if($(this).data('sum2')){
					sum2 = sum2 + value;
				}else{
					sum1 = sum1 + value;
				}
				sum = sum + value;
			});
			
			const sumTd1 = $(this).parent().siblings('.rowSum1');
			$(sumTd1).text(sum1);
			const sumTd2 = $(this).parent().siblings('.rowSum2');
			$(sumTd2).text(sum2);
			
			const sumTd = $(this).parent().siblings('.rowSum');
			$(sumTd).text(sum);
			
			validateNumbers(this);
			
		});
		
		function saveVrstice(){
			//TODO: only if changed fields
			
			const vrstice = $('#scores tr.vloga');
			let validated = true;
			// VALIDATE inputs
			vrstice.each(function( index ) {
				const inputs = $(this).find('input');
				inputs.each(function( index ) {
					validated = validated && validateNumbers(this);
				})
			});
			
			if(!validated){
				toasts.show({
					message: 'Vrednosti niso bile shranjene zaradi napak v vnosnih poljih!',
					color: 'danger'
				});
			}else{
				saveNumbers(vrstice);
				toasts.show({
					message: 'Vrednosti so bile uspešno shranjene.',
				});
			}
		}
		
		function saveNumbers(vrstice){
			let saved = true;
			let savedCount = 0;
			vrstice.each(function( index ) {
				
				const scoreId = $(this).data('scoreid');
				const year = $('select#year').val();
				//const section = $('#section').val();
				
				// shrani po posamezni vrstici
				const inputs = $(this).find('input, select');
				let vnosi = {};
				
				inputs.each(function( index ) {
					vnosi[$(this).attr('name')] = $(this).val();
				})
				
				$.post(` /eva/save/score/adjustment/${scoreId}`, { 'year':year, /* 'section':section, */ 'komisija_data': JSON.stringify(vnosi) }, function(data){
					if(!fnc.isJsonString(data)){
						console.log(data);
					}
					var jsonData = JSON.parse(data);
					if(!jsonData.error){
						savedCount++;
					}else{
						saved = false;
						toasts.show({
							message: 'Zaradi napake pri shranjevanju točk te niso bile shranjene.',
							color: 'danger'
						});
					}
				});
			});
			if(saved && savedCount > 0){
				toasts.show({
					message: 'Vrednosti so bile uspešno shranjene.',
					color: 'success'
				});
			}
		}
		
	}
	
	$( "th a.noOrder" ).click(function( e ) {
		e.stopPropagation();
	});
}

function zakljuciTockovanjeFinal(){
	const selector = '#zakljuciTockovanjeFinal';
	if( $(selector).length > 0 ){
		
		$(selector).on('click',function(e){
			e.preventDefault();
			e.stopPropagation();
			
			$("#modalTockovanje [data-bs-dismiss=modal]").trigger('click');
			$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
			
			const idVloge = $(this).data('id');
			
			$.post(`/eva/vloga/${idVloge}/startustvariodlocbe`, { }, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				if(jsonData.error == false){
					window.location.href = `/eva/vloga/${idVloge}`;
				} else {
					$('.loadingWrapper').removeClass('opacity-loading').trigger('stopLoading');
					toasts.show({
						message:'Prišlo je do napake pri zaključevanju točkovanja!',
						color: 'danger'
					});
				}
			})
		});
	}
}


function startUstvariPogodbe(){
	const selector = '#startPogodbe';
	if( $(selector).length > 0 ){
		$(selector).on('click',function(e){
			e.preventDefault();
			e.stopPropagation();
			
			$("#modalStartPogodbe [data-bs-dismiss=modal]").trigger('click');
			$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
			
			const idVloge = $(this).data('id');
			
			$.post(`/eva/vloga/${idVloge}/startustvaripogodbe`, { }, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				if(jsonData.error == false){
					window.location.href = `/eva/vloga/${idVloge}`;
				} else {
					$('.loadingWrapper').removeClass('opacity-loading').trigger('stopLoading');
					toasts.show({
						message:'Prišlo je do napake!',
						color: 'danger'
					});
				}
			})
		});
	}
	
}

var embedElement = null;
if(document.querySelector('object')){
	var embedElement = document.querySelector('object');
	if(embedElement){
		delectLoadedEmbed();
	}
}
function delectLoadedEmbed(){
	$('.embedPdf .loading-inside').show();
	embedElement.addEventListener('load', function(){
		$('.embedPdf .loading-inside').hide();
	});
}

function startPorocanje(){
	const selector = '#startPorocanjeButton';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			let allInputed = true;
			
			const parent = $(this).parent().parent();
			
			const uredi = $(parent).find('#uredi').val();
			const yearPorocanje = $(parent).find('#yearPorocanje').val();
			const closeYear = $(parent).find('#closeYearPorocanje').val();
			
			const datePorocanje1 = $(parent).find('#datePorocanje1').val();
			if(datePorocanje1 == ''){
				allInputed = false;
			}
			
			let datePorocanje2 = null;
			if($(parent).find('#datePorocanje2')){
				datePorocanje2 = $(parent).find('#datePorocanje2').val();
				if(datePorocanje2 == ''){
					allInputed = false;
				}
			}
			
			let datePorocanje3 = null;
			if($(parent).find('#datePorocanje3')){
				datePorocanje3 = $(parent).find('#datePorocanje3').val();
				if(datePorocanje3 == ''){
					allInputed = false;
				}
			}
			
			if( !allInputed ){
				toasts.show({
					message: 'Izpolniti morate vsa polja!',
					color: 'danger'
				});
				return;
			}
			
			const oDatePorocanje1 = fnc.parseDateWithTime(datePorocanje1)
			
			let oDatePorocanje2 = null;
			if(datePorocanje2){
				oDatePorocanje2 = fnc.parseDateWithTime(datePorocanje2)
			}
			
			/*let oDatePorocanje3 = null;
			if(datePorocanje3){
				oDatePorocanje3 = fnc.parseDateWithTime(datePorocanje3)
			}*/
			
			if(oDatePorocanje2 != null && oDatePorocanje1 >= oDatePorocanje2){
				toasts.show({
					message: 'Datum oddaje končnega poročila je pred datumom oddaje delnega poročila.',
					color: 'danger'
				});
				return;
			}
			
			$("#modalStartPorocanje [data-bs-dismiss=modal]").trigger('click');
			$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
			
			let parameters = {
				'datePorocanje1': datePorocanje1,
				'datePorocanje2': datePorocanje2,
				'datePorocanje3': datePorocanje3,
				'yearPorocanje': yearPorocanje,
				'uredi': uredi,
			}
			
			if(closeYear){
				parameters['closeYear'] = closeYear;
			}
			
			let vlogaID = $('#mainList').data('id');
			$.post(`/eva/editporocanje/${vlogaID}`, parameters, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				
				$('.loadingWrapper').removeClass('opacity-loading').trigger('stopLoading');
				
				if(data.length < 5 || jsonData.error){
					toasts.show({
						message: jsonData.message ? jsonData.message : 'Pri ' + (uredi === '0' ? 'odpiranju' : 'urejanju') + ' poročanja je prišlo do težave!',
						color: 'danger'
					});
					return;
				}
				
				toasts.show({
					message: `Uspešno nastavljeni datumi za poročanje.`,
					time: 100000
				});
				window.location.reload();
			})
		})
	}
}

function sendNotification(){
	const selector = '#sendNotification';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			$("#modalSendEmailPorocanje [data-bs-dismiss=modal]").trigger('click');
			$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
			
			const parameters = {};
			
			let vlogaID = $('#mainList').data('id');
			$.post(`/eva/sendemailporocanje/${vlogaID}`, parameters, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				
				$('.loadingWrapper').removeClass('opacity-loading').trigger('stopLoading');
				
				if(data.length < 5 || jsonData.error){
					toasts.show({
						message: jsonData.message ? jsonData.message : 'Pri pošiljanju obvestil je prišlo do težave!',
						color: 'danger'
					});
					return;
				}
				
				let sended = '';
				for (const email of jsonData.sentEmails){
					sended += email+"<br>";
				}
				
				let notSended = '';
				for (const email of jsonData.notSentEmails){
					notSended += email+"<br>";
				}
				
				toasts.show({
					message: `
						Uspešno obveščeni vlagatelji.
						<br><br>
						Seznam uspešno poslanih e-naslovov:
						<br>
						${sended}
						<br><br>
						Seznam e-naslovov, ki niso poslani:
						<br>
						${notSended}
					`,
					time: 1000000
				});
			})
		})
	}
}

function delay(callback, ms) {
	var timer = 0;
	return function() {
		var context = this, args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function () {
			callback.apply(context, args);
		}, ms || 0);
	};
}

function tenderSearch(){
	
	$("#search").on('keyup', function (e) {
		if (e.key === 'Enter' || e.keyCode === 13) {
			searchTendersOdos($(this).val())
		}
	});
	
	$('#searchOdosTenders').on('click', function(){
		searchTendersOdos($('#search').val())
	});
	
	function searchTendersOdos(searchVal = ''){
		if( searchVal.length >= 5 ){
			$('#searchResults').addClass('opacity-loading').trigger('classChangeLoading', [ true ]);
			$.get('/eva/odos/search/', {'q':searchVal}, function(data){
				$('#searchResults').html('');
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				const jsonData = JSON.parse(data);
				let htmlContent = '';
				
				let idRazpisa = '';
				
				jsonData.forEach((tender) => {
					const SOURCETARGETID = (tender.SOURCETARGETID.length) ? tender.SOURCETARGETID : tender.KLASIFIKACIJAZADEVE;
					const SUBJEKT_SOURCETARGETID = (tender.SUBJEKT_SOURCETARGETID.length) ? tender.SUBJEKT_SOURCETARGETID : '';
					const dateRazpisa = fnc.formatDate(new Date(tender.DATUMVLOGE))
					
					if(idRazpisa == tender.ID){
						// same as previous
						console.log("duplicated");
					}else{
						
					}
					idRazpisa = tender.ID;
					
					htmlContent += `
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 full-width">
								<h3>${tender.ZADEVAOPIS}</h3>
								<div class="mt-2">
									ODOS id razpisa: <b>${tender.ID}</b><br>
									Klasifikacija razpisa: <b>${tender.KLASIFIKACIJAZADEVE}</b><br>
									Oznaka razpisa: <b>${SOURCETARGETID}</b><br>
									Datum razpisa: <b>${dateRazpisa}</b><br>
									SUBJEKT_SOURCETARGETID: <b>${SUBJEKT_SOURCETARGETID}</b>
								</div>
								<div class="mt-2 text-right">
									<button type="button" class="btn btn-primary applytender"
											data-tender='{"odos_sourcetarget_id":"${SOURCETARGETID}","odos_tenderid":"${tender.ID}","odos_tender_description":"${tender.ZADEVAOPIS}","odos_tender_date":"${tender.DATUMVLOGE}", "odos_tender_clasification":"${tender.KLASIFIKACIJA}"}'
											data-bs-dismiss="modal"
											data-bs-target="#tenderSearch">
										Prenesi podatke v ORD razpis
									</button>
								</div>
							</div>
							<span class="badge text-bg-light">ID: ${tender.ID}</span>
						</li>
					`;
				})
				$('#searchResults').html(htmlContent);
			})
			.done(function() {})
			.fail(function() {
				$('#searchResults').html(`<div class="warning"><p>Napaka pri povezavi na ODOS.</p></div>`);
			})
			.always(function() {
				$('#searchResults').trigger('stopLoading');
			});
		}else{
			$('#searchResults').html(`<div class="warning"><p>Iskalni niz mora biti dolg vsaj 5 znakov!</p></div>`);
		}
	}
	
	
	$(document).on('click','.applytender',function(e){
		e.preventDefault();
		var tenderData = $(this).data('tender');
		console.log(tenderData);
		for (const [key, value] of Object.entries(tenderData)) {
			console.log(value, key);
			$('#'+key).val(value);
		}
		$('#tenderSearch').hide();
	});
}

function tenderSearchParams(){
	$('#searchnow').on('click', function(e){
		e.preventDefault();
		$.get('/eva/odos/search/extended/', {
				'klasifikacija':$('#klasifikacija').val(),
				'leto': $('#year').val(),
				'vlagateljid': $('#vlagateljid').val(),
				'vlogaid': $('#vlogaid').val(),
				'klasifikacijazadeve': $('#klasifikacijazadeve').val(),
		}, function(data){
			$('#searchResults').html('');
			var jsonData = JSON.parse(data);
			jsonData.forEach((tender) => {
				$('#searchResults').append(`
					<li class="list-group-item">
						<input class="form-check-input me-1" type="radio" name="caseId" value="${tender.ID}" id="caseId${tender.ID}">
						<label class="form-check-label" for="caseId${tender.ID}">${tender.ZADEVAOPIS}<br>${tender.KLASIFIKACIJAZADEVE} - ${tender.DATUMVLOGE}</label>
					</li>
				`);
			});
		})
	});
	$('#savedata').on('click', function(e){
		var caseId = $('input[name="caseId"]:checked').val();
		var userId = $('#userid').val();
		var vlogaId = $('#vlogaid').val();
		$.post('/eva/odos/set/caseid/', {
			'caseId':caseId,
			'userId':userId,
			'vlogaId':vlogaId
		}, function(data){
			var jsonData = JSON.parse(data);
			if(jsonData.error == true){
				alert("Prišlo je do napake pri nastavljanju!");
			} else {
				window.location.reload();
			}
		})
	});
}

function openTender(){
	if($('.opentender').length){
		$('.opentender').on('click', function(e){
			e.preventDefault();
			if($('#title').val() == ''){
				toasts.show({
					message:'Vpišite naslov razpisa!',
					color: 'danger',
					time: 10000
				});
				return false;
			}
			var tenderName = $('#title').val();
			if($('#document_number').val() == ''){
				toasts.show({
					message:'Vpišite številko dokumenta!',
					color: 'danger',
					time: 10000
				});
				return false;
			}
			var tenderNumber = $('#document_number').val();
			if($('#open_from').val() == ''){
				toasts.show({
					message:'Vpišite datum odprtja razpisa!',
					color: 'danger',
					time: 10000
				});
				return false;
			}
			var tenderDate = $('#open_from').val();
			if($('#klasifikacija option:selected').val() == ''){
				toasts.show({
					message:'Izberite klasifikacijo!',
					color: 'danger',
					time: 10000
				});
				return false;
			}
			var tenderKlasification = $('#klasifikacija option:selected').val();
			if($('#tenderadmin option:selected').val() == ''){
				toasts.show({
					message:'Izberite skrbnika!',
					color: 'danger',
					time: 10000
				});
				return false;
			}
			var tenderAdmin = $('#tenderadmin option:selected').val();
			var tenderWorkplace = $('#tenderadmin option:selected').data('workplace');
			var tenderData = {
				'tenderName':tenderName,
				'tenderNumber':tenderNumber,
				'tenderKlasification':tenderKlasification,
				'tenderAdmin':tenderAdmin,
				'tenderWorkplace':tenderWorkplace,
				'tenderDate':tenderDate
			};
			
			$.post(window.location.href+'/opentender', tenderData, function(data){
				var jsonData = JSON.parse(data);
				if(jsonData[0].validated == 1){
					window.location.reload();
				}
			})
		});
	}
}

function saveReportComments(){
	var disabled = $('form#porocilo input:disabled').removeAttr('disabled');
	const formData = fnc.getFormData($('form#porocilo'), 'comment_');
	
	var formDataJson = JSON.stringify(formData);
	disabled.attr('disabled','disabled');
	let recordID = $('form#porocilo').data('reportid');
	
	$.post(`/eva/report/${recordID}/savecomments`, { 'data':formDataJson }, function(data){
		if(!fnc.isJsonString(data)){
			console.log(data);
		}
		var jsonData = JSON.parse(data);
		if(data.length > 5 && !jsonData.error){
			toasts.show({
				message:'Komentarji so bili shranjeni kot osnutek.'
			});
		}else{
			toasts.show({
				message:'Pri shranjevanju komentarjev je prišlo do napake!',
				color: 'danger'
			});
		}
	})
}

function saveReportCommentsTrigger(){
	const selector = '#saveCommentsReport';
	if($(selector).length){
		
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			saveReportComments();
		})
		
		const interValSaveComments = setInterval(function(){
			$(selector).trigger('click');
		},60000);
	}
}

function reportSupplemet(){
	const selector = '#report_supplementation';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('form#porocilo'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			
			if (!isEmpty) { // detect if new comments
				saveReportComments();
				
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				let vlogaID = $('form#porocilo').data('id');
				let recordID = $('form#porocilo').data('reportid');
				
				$.post(`/eva/report/${recordID}/supplement`, { }, function(data){
					if(!fnc.isJsonString(data)){
						console.log(data);
					}
					
					let error = false;
					if(fnc.isJsonString(data)){
						var jsonData = JSON.parse(data);
						if(data.length > 5 && !jsonData.error){
							
							window.location.href = `/eva/vloga/${vlogaID}`;
							$('.loadingWrapper').removeClass('opacity-loading').trigger('stopLoading');
							
						} else {
							error = true;
						}
					} else {
						error = true;
					}
					if(error){
						$('.loadingWrapper').removeClass('opacity-loading').trigger('stopLoading');
						var text = 'Pri oddajanu vloge v dopolnjevanje je prišlo do težave!';
						if(jsonData.text){
							text = jsonData.text;
						}
						toasts.show({
							message: text,
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Ni komentarjev!<br>Če želite poslati to poročilo v dopolnjevanje, morate vnesti komentar, ki se nato vpiše v poziv na dopolnitve.',
					color: 'danger',
					time: 30000
				});
				$(`#modalDopolnitev [data-bs-dismiss=modal]`).trigger('click');
			}
		})
	}
}

function reportConfirm(){
	const selector = '#report_confirm';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('form#porocilo'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			
			if (isEmpty) { // detect if new comments
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				//let razpisID = $('form#porocilo').data('id');
				let vlogaID = $('form#porocilo').data('dataid');
				let recordID = $('form#porocilo').data('reportid');
				
				$.post(`/eva/report/${recordID}/confirm`, { }, function(data){
					if(!fnc.isJsonString(data)){
						console.log(data);
					}
					var jsonData = JSON.parse(data);
					if(data.length > 5 && !jsonData.error){
						window.location.href = `/eva/vloga/${vlogaID}/reports`;
					}else{
						toasts.show({
							message:'Pri potrjevanju poročila je prišlo do težave!',
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Obstajajo neoddani komentarji.<br>Vlagatelj ne bo videl teh novih komentarjev, če bo poročilo potrjeno.<br>Če želite potrditi to poročilo, izbrišite komentarje.',
					color: 'danger',
					time: 30000
				});
				
				$("#modalPotrdi [data-bs-dismiss=modal]").trigger('click');
			}
		})
	}
}

function finishTender(){
	const selector = '#finishTender';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			const vlogaId = $(this).data('vloga');
			
			$.post(`/eva/vloga/${vlogaId}/finishvloga`, { }, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				if(jsonData.error == false){
					window.location.href = `/eva`;
				} else {
					toasts.show({
						message:'Prišlo je do napake pri zaključevanju vloge!',
						color: 'danger'
					});
				}
			})
			
		})
	}
}



function saveComments(){
	var disabled = $('#vloga input:disabled').removeAttr('disabled');
	const formData = fnc.getFormData($('#vloga'), 'comment_');
	
	var formDataJson = JSON.stringify(formData);
	disabled.attr('disabled','disabled');
	let recordID = $('#vloga').data('dataid');
	
	$.post(`/eva/savecomments/${recordID}`, { 'data':formDataJson }, function(data){
		if(!fnc.isJsonString(data)){
			console.log(data);
		}
		var jsonData = JSON.parse(data);
		if(data.length > 5 && !jsonData.error){
			toasts.show({
				message:'Komentarji so bili shranjeni kot osnutek.'
			});
		}else{
			toasts.show({
				message:'Pri shranjevanju komentarjev je prišlo do napake!',
				color: 'danger'
			});
		}
	})
	
}

function saveCommentsClickTrigger(){
	if($('#saveComments').length){
		$('#saveComments').on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			saveComments();
		})
	}
}

function timeSaveCommentsTrigger(){
	if($('#saveComments').length){
		var interValSaveComments = setInterval(function(){
			$('#saveComments').trigger('click');
		},60000);
	}
}

function supplementationApplication(){
	const selector = '#supplementation';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('#vloga'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			if(!isEmpty) { // can return if at least one new comment
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				saveComments();
				
				//let vlogaID = $('#vloga').data('id');
				let recordID = $('#vloga').data('dataid');
				
				$.post(`/eva/supplementation/${recordID}`, { }, function(data){
					let error = false;
					if(fnc.isJsonString(data)){
						var jsonData = JSON.parse(data);
						if(data.length > 5 && !jsonData.error){
							window.location.href = `/eva/editor/${recordID}`;
							//$('.loadingWrapper').removeClass('opacity-loading').trigger('stopLoading');
						} else {
							error = true;
						}
					} else {
						error = true;
					}
					if(error){
						$('.loadingWrapper').removeClass('opacity-loading').trigger('stopLoading');
						var text = 'Pri oddajanu vloge v dopolnjevanje je prišlo do težave!';
						if(jsonData.text){
							text = jsonData.text;
						}
						toasts.show({
							message: text,
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Ni komentarjev!<br>Če želite vrniti vlogo v dopolnjevanje, morate vnesti komentar, ki se nato vpiše v poziv na dopolnitve.',
					color: 'danger',
					time: 30000
				});
				$(`#modalDopolnitev [data-bs-dismiss=modal]`).trigger('click');
			}
		})
	}
}

function vrociDopolnitve(){
	const selector = '#vrociDopolnitve';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			const dateDopolnitev = $('#dateDopolnitev').val();
			if(dateDopolnitev != ''){
				$("#modalDopolnitve [data-bs-dismiss=modal]").trigger('click');
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				let vlogaID = $('#mainList').data('id');
				$.post(`/eva/dopolnitve/${vlogaID}`, { 'date': dateDopolnitev }, function(data){
					if(!fnc.isJsonString(data)){
						console.log(data);
					}
					var jsonData = JSON.parse(data);
					if(data.length > 5 && !jsonData.error){
						toasts.show({
							message:'Vloge so bile vročene v dopolnitve. Dopolnitve so odprte do: '+dateDopolnitev,
							time: 100000
						});
						window.open(`${jsonData.pathZip}?v${Date.now()}`, "_blank");
					}else{
						toasts.show({
							message: jsonData.message ? jsonData.message : 'Pri vročanju vlog v dopolnitve je prišlo do težave!',
							color: 'danger'
						});
					}
					$('.loadingWrapper').removeClass('opacity-loading').trigger('stopLoading');
					window.location.reload();
				})
			}else{
				// manjka datum!
				toasts.show({
					message: 'Vnesi datum zaprtja dopolnitev!',
					color: 'danger'
				});
			}
		})
	}
}

function confirmApplication(){
	const selector = '#confirm';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('#vloga'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			
			if (isEmpty) { // detect if new comments
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				let vlogaID = $('#vloga').data('id');
				let recordID = $('#vloga').data('dataid');
				
				$.post(`/eva/confirm/${recordID}`, { }, function(data){
					if(!fnc.isJsonString(data)){
						console.log(data);
					}
					var jsonData = JSON.parse(data);
					if(data.length > 5 && !jsonData.error){
						window.location.href = `/eva/vloga/${vlogaID}`;
					}else{
						toasts.show({
							message:'Pri oddajanu vloge v točkovanje je prišlo do težave!',
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Obstajajo neoddani komentarji.<br>Vlagatelj ne bo videl teh novih komentarjev, če bo vloga potrjena.<br>Če želite potrditi to vlogo, izbrišite komentarje.',
					color: 'danger',
					time: 30000
				});
				
				$("#modalVTockovanje [data-bs-dismiss=modal]").trigger('click');
			}
		})
	}
}

function rejectApplication(){
	const selector = '#reject';
	if($(selector).length){
		$(selector).on('click', function(e){ // Zavrni z odločbo
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('#vloga'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			
			if (isEmpty) { // detect if new comments
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				let vlogaID = $('#vloga').data('id');
				let recordID = $('#vloga').data('dataid');
				
				$.post(`/eva/reject/${recordID}`, { 'rejectType': 'odlocba' }, function(data){
					if(!fnc.isJsonString(data)){
						console.log(data);
					}
					var jsonData = JSON.parse(data);
					if(data.length > 5 && !jsonData.error){
						window.location.href = `/eva/vloga/${vlogaID}`;
					}else{
						toasts.show({
							message:'Pri označevanju vloge kot zavrnjene z odločbo je prišlo do težave!',
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Obstajajo neoddani komentarji.<br>Vlagatelj ne bo videl teh novih komentarjev, če bo vloga zavrnjena.<br>Če želite zavrniti to vlogo, izbrišite komentarje.',
					color: 'danger',
					time: 30000
				});
				$(`#modalZavrni [data-bs-dismiss=modal]`).trigger('click');
			}
		})
	}
	
	const selector2 = '#reject2';
	if($(selector2).length){
		$(selector2).on('click', function(e){ // Zavrži s sklepom
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('#vloga'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			
			if (isEmpty) { // detect if new comments
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				let vlogaID = $('#vloga').data('id');
				let recordID = $('#vloga').data('dataid');
				
				$.post(`/eva/reject/${recordID}`, { 'rejectType': 'sklep' }, function(data){
					if(!fnc.isJsonString(data)){
						console.log(data);
					}
					var jsonData = JSON.parse(data);
					if(data.length > 5 && !jsonData.error){
						window.location.href = `/eva/vloga/${vlogaID}`;
					}else{
						toasts.show({
							message:'Pri označevanju vloge kot zavrnjene s sklepom je prišlo do težave!',
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Obstajajo neoddani komentarji.<br>Vlagatelj ne bo videl teh novih komentarjev, če bo vloga zavrnjena.<br>Če želite zavrniti to vlogo, izbrišite komentarje.',
					color: 'danger',
					time: 30000
				});
				$(`#modalZavrni [data-bs-dismiss=modal]`).trigger('click');
			}
		})
	}
}


function docTypeShow(){
	$('select#document_type').on("change", function(e){
		$('.fieldsList.show').removeClass('show');
		const value = $(this).val();
		$(`.fieldsList.${value}`).addClass('show');
	})
}

$(function($){
	
	deleteUser();
	
	zacniTockovanje();
	if($('.scoringPage').length > 0){
		scoringPage();
	}
	
	listKomisije();
	commissionFinish();
	saveKomisijaPopravki();
	zakljuciTockovanjeFinal();
	startUstvariPogodbe();
	
	closeSupplButton();
	returnButton();
	
	startPorocanje();
	sendNotification();
	
	defineUploadAdmin();
	saveFileAdmin();
	deleteFileAdmin();
	reorderFilesAdmin();
	
	saveDocumentsData();
	
	
	saveCommentsClickTrigger();
	timeSaveCommentsTrigger();
	
	supplementationApplication();
	vrociDopolnitve();
	
	confirmApplication();
	rejectApplication();
	
	
	// ODOS
	tenderSearch();
	tenderSearchParams();
	openTender();
	
	// porocilo
	saveReportCommentsTrigger();
	reportSupplemet();
	reportConfirm();
	
	finishTender();
	
	docTypeShow();
});