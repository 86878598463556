const uploadFile = function(file, fieldName, inputParent) {
	
	const inputFile = $(inputParent).find(`input[type="file"]`);
	const recordId = $(inputFile).data('recordid');
	
	var supplement = $('form#vloga,form#porocilo').data('supplement');
	supplement = supplement === undefined ? false : supplement;
	
	const API_ENDPOINT = `/evloge/vloga/${recordId}/upload?fieldname=${fieldName}&supplement=${supplement}`;
	const request = new XMLHttpRequest();
	const formData = new FormData();
	formData.append("file", file);
	
	request.upload.onload = () => {} // everything is in onreadystatechange
	request.upload.onerror = () => {
		toasts.show({
			message:`Napaka pri nalaganju datoteke <span class="fileName">${file.name}</span>.`,
			color: 'danger',
			time: 10000
		});
	}
	request.upload.onabort = () => {
		toasts.show({
			message:`Nalaganje datoteke <span class="fileName">${file.name}</span> je bilo preklicano.`,
			color: 'danger',
			time: 10000
		});
	}
	request.upload.onprogress = event => {
		/*$('#progressbar').show();
		if (event.lengthComputable) {
			var percentComplete = (event.loaded / event.total) * 100;
			$('#progressbar .bar').css('width', percentComplete+'%');
		}*/
	}
	request.onreadystatechange = () => {
		if ( request.readyState === 4 && request.status === 200 ) {
			
			/*var progressTimeout = setTimeout(function(){
				$('#progressbar').hide();
				$('#progressbar .bar').css('width', '0%');
			}, 1000)*/
			
			if(!fnc.isJsonString(request.responseText)){
				console.log(request.responseText);
			}
			
			var jsonData = JSON.parse(request.responseText);
			if ( !jsonData.error ){
				const numSiblings = inputParent.find(`input[type="hidden"].${jsonData.fieldname}`).length;
				const fileSize = bytesToMB(file.size);
				
				let newFileElement = jsonData.html;
				newFileElement = newFileElement.replaceAll('{number}', numSiblings);
				newFileElement = newFileElement.replace('{filesize}_user', fnc.numberWithCommas(fileSize));
				newFileElement = newFileElement.replace('{filesize}', fileSize);
				newFileElement = $(newFileElement);
				
				//const sumFiles = $(inputParent).find('.sumFiles');
				//newFileElement.insertBefore(sumFiles);
				
				const selectedFiles = $(inputParent).find('.selectedFiles');
				$(selectedFiles).append(newFileElement);
				
				/*
				const sizeSumSpan = $(sumFiles).find('.size');
				var fileSizeSum = 0;
				$(inputParent).find('.fileuploaded').each(function( index ) {
					const item = $( this ).find(`input[type="hidden"].${jsonData.fieldname}FileSize`);
					fileSizeSum = fileSizeSum + parseFloat(item.val());
				});
				fileSizeSum = parseFloat(fileSizeSum.toFixed(2));
				sizeSumSpan.html(`${fnc.numberWithCommas(fileSizeSum)} MB`);
				*/
				
				const isUploaded = inputParent.find(`input[type="hidden"].isUploaded`);
				$(isUploaded).val('true');
				
				// remove invalid-feedback
				const invalidFeedback = inputParent.find(`.invalid-feedback`);
				invalidFeedback.remove();
				
				$('#save').trigger('click', ['uploadFile']);
				$('#savePorocilo').trigger('click', ['uploadFile']);
				
				toasts.show({
					message:`Datoteka <span class="fileName">${file.name}</span> je bila uspešno naložena.`,
					color: 'success',
					time: 10000
				});
				document.dispatchEvent( new CustomEvent("fileUploaded", { filename: file.name }));
			} else {
				toasts.show({
					message:jsonData.error,
					color: 'danger',
					time: 10000
				});
			}
		}
	};
	
	request.open("POST", API_ENDPOINT, true);
	request.send(formData);
};

function bytesToMB(bytes) {
	const transformed = (bytes / (1024 * 1024));
	return parseFloat(transformed.toFixed(2));
}

function fileDropZone(){
	$(document).on('click', '.fileDropZone', function(e){
		e.preventDefault();
		e.stopPropagation();
		const fileUploadInputId = this.dataset.fileuploadinputid;
		const input = document.getElementById(fileUploadInputId);
		if(input){
			input.click();
		}
	});
}

function dropFile(){
	$(document).on('dragover', '.fileDropZone', function(e){
		e.preventDefault();
		e.stopPropagation();
	});
	$(document).on('drop', '.fileDropZone', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		let dropZoneEl = e.target;
		if(dropZoneEl.tagName === 'SPAN'){
			dropZoneEl = dropZoneEl.parentElement;
		}
		const fileUploadInputId = dropZoneEl.dataset.fileuploadinputid;
		if(fileUploadInputId == "uploadAdmin"){
			return true; // admin.js handles this!
		}
		
		processFiles([...e.originalEvent.dataTransfer.files], $(`#${fileUploadInputId}`));
	});
}

function detectFileAdded(){
	$(document).on("change", 'input[type="file"]', function(e){
		if($(e.target).hasClass('noupload')) {
			return true;
		}
		
		processFiles([...e.target.files], $(this));
	});
}

const processFiles = (files, parent) => {
	const fieldName = parent.data('name');
	const inputParent = parent.closest('.files_wrapper');
	for (const file of files) {
		handleFile(file, fieldName, inputParent);
	}
};

function handleFile(file, fieldName, inputParent){
	
	const fileInput = document.querySelector(`input[type="file"]#${fieldName}`)
	const limitMB = parseInt(fileInput.dataset.maxfilesizemb); // in MB
	const factor = 1000000;
	
	if( file.size < limitMB * factor ){
		uploadFile(file, fieldName, inputParent);
	} else {
		const fileSize = Math.round(file.size / factor * 10) / 10;
		const timesBigger = Math.ceil(file.size / (limitMB * factor));
		
		toasts.show({
			message:`<big>Velikost posamezne datoteke je omejena na <b>${limitMB}&nbsp;MB</b>!</big>
			<br><br><br>
			Vaša izbrana datoteka <span class="fileName">${file.name}</span> je velika ${fileSize}&nbsp;MB, kar pomeni da morate datoteko razbiti na ${timesBigger} dele.`,
			color: 'danger',
			time: 100000
		});
	}
}

var thisItem = null;
var filename = '';

function clickDeleteFile(){
	$(document).on('click', '.deletefile', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		filename = $(this).data('file');
		thisItem = this;
		
		const id = $(this).data('id');
		
		$(`#removeFile_${id}`).modal('show');
		$(`#removeFileLabel_${id} span`).text(filename);
	});
	
	$(".removeFileBtn").on("click", function(){
		
		const fileInput = $(this).parents('.files_wrapper').find('input[type="file"]');
		
		const izjava = fileInput.data('izjava');
		const fileName = $(this).data('file');
		
		var supplement = $('#vloga, #porocilo').data('supplement');
		
		let recordId = $('#vloga').data('dataid');
		
		let reportId = $('#porocilo').data('reportid');
		if(reportId){
			recordId = reportId;
		}
		
		if( izjava ){
			recordId = fileInput.data('recordid');
		}
		
		const parentFile = $(thisItem).parent('.fileuploaded');
		
		const fileDBId = $(parentFile).find('.fileNameHidden').attr('name');
		var link = '/evloge/vloga/'+recordId+'/delete/'+filename;
		link = link + '?fileId=' + fileDBId
		
		if(izjava){
			link = link + '&izjava=true'
		}
		
		if(supplement){
			
			const hiddenField = $(parentFile).find('.fileNameHidden')[0];
			const idForInputFiles = $(hiddenField).data('id');
			
			const $suppl = $(parentFile).find('.'+idForInputFiles+'Supplement');
			const valSup = $suppl.val();
			
			if(!hiddenField.classList.contains('dopolnitve') && valSup != 'true'){
				toasts.show({
					message:`Datoteka <span class="fileName">${filename}</span> ni bila dodana kot dopolnitev!`,
					color: 'danger',
					time: 10000
				});
				$("#removeFile [data-bs-dismiss=modal]").trigger('click');
				return;
			}
		}
		
		$.post(link, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(!jsonData.error){
				const inputParent = $(parentFile).closest('.files_wrapper');
				
				parentFile.remove();
				
				const allUploadedFiles = inputParent.find(`.selectedFiles .fileuploaded`);
				allUploadedFiles.each(function( indexFile ) {
					const allHiddenFields = $(this).find(`input[type="hidden"]`);
					allHiddenFields.each(function( indexField ) {
						const fieldName = $(this).attr('name')
						const match = fieldName.match(/\[(\d+)\]/);
						const number = match ? match[1] : null;
						if(number != indexFile){
							const updatedStr = fieldName.replace(/\[(\d+)\]/, `[${indexFile}]`);
							$(this).attr('name', updatedStr);
						}
					});
				});
				
				if(izjava){
					document.dispatchEvent( new CustomEvent("fileDeleted", { filename: fileName }));
				}else{
					$('#save').trigger('click', ['uploadFile']);
					$('#savePorocilo').trigger('click', ['uploadFile']);
				}
				
				/*
				const fieldname = $(inputParent).find('input[type="file"]').attr('id');
				var fileSizeSum = parseFloat(0);
				$(inputParent).find(`.fileuploaded input[type="hidden"].${fieldname}FileSize`).each(function( index ) {
					fileSizeSum = fileSizeSum + parseFloat($( this ).val());
				});
				
				fileSizeSum = String(fileSizeSum.toFixed(2)).replace('.',',');
				
				const sizeSumSpan = $(inputParent).find('.sumFiles .size');
				sizeSumSpan.html(`${fileSizeSum} MB`);
				*/
				
				toasts.show({
					message:`Datoteka <span class="fileName">${filename}</span> je bila odstranjena.`,
					time: 10000
				});
				
				const uploadedFiles = inputParent.find(`.selectedFiles .fileuploaded`);
				if(uploadedFiles.length == 0){
					const isUploaded = inputParent.find(`input[type="hidden"].isUploaded`);
					$(isUploaded).val('false');
				}
				
			} else {
				toasts.show({
					message:jsonData.message,
					color: 'danger',
					time: 10000
				});
			}
		});
		
		$(this).parents('.modal').find("[data-bs-dismiss=modal]").trigger('click');
	});
}

$(function(){
	fileDropZone();
	dropFile();
	detectFileAdded();
	clickDeleteFile();
});
